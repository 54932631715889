<template>
    <div class="memberDetailView">
        <div class="topView">
            <div class="backView">
                <!-- @click="dialogTableVisible = false" -->
                <el-button class="btn-back" icon="el-icon-arrow-left" @click="goBack">
                    返回
                </el-button>
            </div>
            <div class="contentView">
                <div class="tView">订单详情</div>
                <div>
                    <el-button type="primary" @click="printPaper">打印小票</el-button>
                    <el-button type="primary" @click="printLabel">打印条码</el-button>
                    <!-- <el-button
                      type="primary"
                      @click="cancelOrder(orderDetailOption.orderNumber)"
                    >
                      取消订单
                    </el-button> -->
                    <el-button type="primary" class="orderRem" @click="goToReminder">
                        催单订单
                        <!-- <i v-if="orderRemNumber>0">
                    {{ orderRemNumber }}</i> -->
                    </el-button>
                    <el-button
                            type="warning"
                            style="background: #e6a23c"
                            v-if="
              orderDetailOption.payStatus === '未支付' &&
                orderDetailOption.orderStatus !== '已取消' &&
                orderDetailOption.orderStatus !== '已退款'
            "
                            @click="handlePayModal"
                    >
                        去支付
                    </el-button>
                    <el-button
                            type="warning"
                            style="background: #e6a23c"
                            v-if="
              orderDetailOption.payStatus === '未支付' &&
                orderDetailOption.orderStatus !== '已取消' &&
                  orderDetailOption.orderStatus !== '已退款' &&
                (orderDetailOption.payWay === '收钱吧' ||
                  orderDetailOption.payWay === '线上支付' ||
                  orderDetailOption.orderSource === '美团订单' ||
                  orderDetailOption.orderSource === '抖音订单')
            "
                            @click="manualPayResult(orderDetailOption.thirdOrderNum)"
                    >
                        手动查询
                    </el-button>

                    <el-button
                            type="warning"
                            style="background: #e6a23c"
                            v-if="orderDetailOption.payStatus === '待补款'"
                            @click="handleDiffPayModal"
                    >
                        去补款
                    </el-button>
                </div>
            </div>
        </div>
        <div
                class="contentView"
                style="height: 669px; margin-top: 20px"
                v-loading="orderInfoLoading"
                element-loading-text="加载中..."
        >
            <div class="clothesInfo-contentView">
                <div style="display: flex">
                    <div class="infoView">
                        <div>
                            <label class="label-leftText">单号：</label>
                            <label>{{ orderDetailOption.orderNumber }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">订单来源：</label>
                            <label>{{ orderDetailOption.orderSource }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">消费日期：</label>
                            <label>{{ orderDetailOption.orderTime }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">订单状态：</label>
                            <label>{{ orderDetailOption.orderStatus }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">支付状态：</label>
                            <label style="color: red">
                                {{ orderDetailOption.payStatus }}
                            </label>
                        </div>
                        <div>
                            <label class="label-leftText">支付时间：</label>
                            <label style="color: red">
                                {{
                                orderDetailOption.payStatus == "未支付"
                                    ? "暂无"
                                    : orderDetailOption.paymentTime
                                }}
                            </label>
                        </div>
                    </div>
                    <div class="infoView">
                        <div>
                            <label class="label-leftText">用户姓名：</label>
                            <label>{{ orderDetailOption.orderRealName }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">手机号：</label>
                            <label>{{ orderDetailOption.orderUserPhone }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">地址：</label>
                            <label>{{ orderDetailOption.userAddress }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">操作员：</label>
                            <label>{{ orderDetailOption.storeUserName }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">所属门店：</label>
                            <label>{{ orderDetailOption.storeName }}</label>
                        </div>
                    </div>
                </div>
                <div class="contentView">
                    <div class="tView">
                        <span>衣物详情(件数: {{ orderDetailOption.clothesNum }}):</span>
                        <el-button
                                icon="el-icon-plus"
                                style="color:#fff;background:#860404;border:1px solid #860404"
                                type="primary"
                                size="small"
                                @click="queryReminder"
                        >
                            发送催单
                        </el-button>
                    </div>
                    <div class="cashContentView">
                        <div class="headerView flexView" style="color: #999">
                            <label>选项</label>
                            <label style="flex: 1.5">条码号</label>
                            <label>衣物状态</label>
                            <label>衣物名称</label>
                            <label>颜色</label>
                            <label>原价/折后价</label>
                            <label>平方数</label>
                            <label>优惠券</label>
                            <label>是否售后</label>
                            <label>售后状态</label>
                            <label>催单状态</label>
                            <label style="flex: 1.4">操作</label>
                        </div>
                        <div
                                class="flexView"
                                v-for="(item, index) in orderDetailOption.clothesList"
                                :key="index"
                        >
                            <label>
                                <el-checkbox
                                        :value="
                    (priCheckedClothesList.indexOf(item.id) === -1 ? '-' : '') +
                      item.id
                  "
                                        :true-label="item.id"
                                        :false-label="'-' + item.id"
                                        @change="changePriCheck($event, item)"
                                ></el-checkbox>
                            </label>
                            <label style="flex: 1.5">
                                <el-tag size="mini" type="success" v-if="item.ifRewash">
                                    返
                                </el-tag>
                                <el-tag
                                        size="mini"
                                        type="info"
                                        v-if="item.clothesStatus === '已退洗'"
                                >
                                    退
                                </el-tag>
                                <el-tag size="mini" v-if="item.accessoryNumber">附</el-tag>
                                {{ item.outClothesNum }}
                            </label>
                            <label>{{ item.clothesStatus }}</label>
                            <label>{{ item.clothesName }}</label>
                            <label>{{ item.colorName }}</label>
                            <label v-if="!item.accessoryNumber">
                                {{ item.originalPrice }}元/{{ item.currentPrice }}元
                            </label>
                            <label v-else>0元</label>
                            <label v-if="item.squareNum">{{ item.squareNum }}m²</label>
                            <label v-else>0m²</label>
                            <label>
                                {{
                                item.couponId != null && item.couponId !== 0
                                    ? "已使用"
                                    : "未使用"
                                }}
                            </label>
                            <label>
                                {{ item.isAfterSale }}
                            </label>
                            <label>
                                {{ item.afterSaleStatusDesc }}
                            </label>
                            <label>
                                {{ item.reminderStatusDesc }}
                            </label>
                            <label style="flex: 1.4">
                                <el-button
                                        type="primary"
                                        size="mini"
                                        v-if="!item.accessoryNumber && item.clothesStatus == '已收衣'"
                                        @click="updateClothes(item)"
                                >
                                    修改
                                </el-button>
                                <el-button
                                        type="primary"
                                        size="mini"
                                        @click="showDialogDetail(item)"
                                >
                                    详情
                                </el-button>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="payInfoView">
                    <div class="tView">
                        金额
                        <el-button type="text">修改支付方式</el-button>
                    </div>
                    <div>
                        <div class="amtView">
                            <div>
                                <label class="label-leftText">衣物原价：</label>
                                <label class="label-amt">
                                    ¥ {{ orderDetailOption.productOriginalPrice }}
                                </label>
                            </div>
                            <div>
                                <label class="label-leftText">运费：</label>
                                <label class="label-amt">¥ {{ orderDetailOption.fare }}</label>
                            </div>
                            <div v-if="orderDetailOption.abnormal == 2">
                                <label class="label-leftText">衣物改价：</label>
                                <label class="label-amt">
                                    ¥ {{ orderDetailOption.abnormalAmount }}
                                </label>
                            </div>
                            <div>
                                <label class="label-leftText">优惠金额：</label>
                                <label class="label-amt">
                                    ¥ {{ orderDetailOption.discountPrice }}
                                </label>
                            </div>
                            <div>
                                <label class="label-leftText">订单总额：</label>
                                <label class="label-amt">
                                    ¥ {{ orderDetailOption.actuallyPaidPrice }}
                                </label>
                            </div>
                        </div>
                        <div class="payTypeView">
                            <div class="payTypeItem">
                                <label class="label-leftText">支付方式：</label>
                                <label class="label-name">{{ orderDetailOption.payWay }}</label>
                            </div>
                            <div class="payTypeItem">
                                <label class="label-leftText">优惠券金额：</label>
                                <label class="label-name">
                                    ￥{{ orderDetailOption.couponAmount }}
                                </label>
                            </div>
                            <div class="payTypeItem">
                                <label class="label-leftText">优惠券名称：</label>
                                <label class="label-name">
                                    {{ orderDetailOption.couponName }}
                                </label>
                            </div>
                            <div class="payTypeItem">
                                <label class="label-leftText">优惠券详情：</label>
                                <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="orderDetailOption.couponDesc"
                                        placement="top"
                                >
                                    <label class="label-name">
                                        {{ orderDetailOption.couponDesc }}
                                    </label>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="remarkView">
                            <div class="remarkCtn">
                                <label class="label-leftText">顾客备注信息：</label>
                                <label class="remark-box">
                                    {{ orderDetailOption.orderRemark }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--衣物详情-->
        <pop-view
                id="order-detailViewPopView"
                :show="detailView"
                width="700px"
                title="衣物详情"
                @cancel="detailView = false"
        >
            <div class="menuView">
                <button
                        :class="{ select: currentTab === item.val }"
                        v-for="(item, index) in tabs"
                        :key="index"
                        @click="handleDetailTab(item.val)"
                >
                    {{ item.title }}
                </button>
            </div>
            <div
                    class="contentView"
                    style="margin-top: 20px"
                    v-if="currentTab === 'info'"
            >
                <div style="display: flex">
                    <div class="infoView">
                        <div>
                            <label class="label-leftText">衣物名称：</label>
                            <label>{{ clothes.clothesName }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">衣物号：</label>
                            <label>{{ clothes.clothesNum }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">洗涤方式：</label>
                            <label>{{ clothes.clothesServiceName }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">衣物状态：</label>
                            <label>{{ clothes.clothesStatus }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">洗后效果：</label>
                            <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="clothes.effects"
                                    placement="top"
                            >
                                <label class="label-ellipsis">{{ clothes.effects }}</label>
                            </el-tooltip>
                        </div>
                        <div>
                            <label class="label-leftText">是否售后：</label>
                            <label>{{ clothes.isAfterSale }}</label>
                        </div>
                    </div>
                    <div class="infoView">
                        <div>
                            <label class="label-leftText">颜色：</label>
                            <label>{{ clothes.colorName }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">品牌：</label>
                            <label>{{ clothes.brandName }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">衣物价格：</label>
                            <label>¥{{ clothes.currentPrice }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">单价：</label>
                            <label v-if="clothes.unitPrice">¥{{ clothes.unitPrice }}</label>
                            <label v-else>0</label>
                        </div>
                        <div>
                            <label class="label-leftText">平方数：</label>
                            <label v-if="clothes.squareNum">{{ clothes.squareNum }}m²</label>
                            <label v-else>0m²</label>
                        </div>
                        <div>
                            <label class="label-leftText">售后状态：</label>
                            <label>{{ clothes.afterSaleStatusDesc }}</label>
                        </div>
                    </div>
                </div>
                <div style="display: flex">
                    <div class="infoView">
                        <div>
                            <label class="label-leftText">瑕疵：</label>
                            <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="clothes.blemishes"
                                    placement="top"
                            >
                                <label class="label-ellipsis">{{ clothes.blemishes }}</label>
                            </el-tooltip>
                        </div>
                        <div>
                            <label class="label-leftText">备注：</label>
                            <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="clothes.remark"
                                    placement="top"
                            >
                                <label class="label-ellipsis">{{ clothes.remark }}</label>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="infoView">
                        <div>
                            <label class="label-leftText">预计取衣时间：</label>
                            <label>{{ clothes.estimatedWashTime }}</label>
                        </div>
                        <div>
                            <label class="label-leftText">工厂时效：</label>
                            <label>{{ clothes.factoryAging }}天</label>
                        </div>
                    </div>
                </div>
                <div
                        style="
            display: flex;
            margin-top: 20px;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: space-between;
          "
                >
                    <div v-if="clothes.photos !== null">
                        <el-image
                                style="margin-left: 10px; width: 22%; margin-bottom: 10px"
                                v-for="url in clothes.photos"
                                :key="url"
                                :src="url"
                                :preview-src-list="clothes.photos"
                                lazy
                        ></el-image>
                    </div>
                </div>
            </div>
            <div
                    class="contentView"
                    style="margin-top: 20px"
                    v-if="currentTab === 'status'"
            >
                <el-timeline>
                    <el-timeline-item
                            v-for="(activity, index) in clothesStatusList"
                            :key="index"
                            color="#0bbd87"
                            :timestamp="activity.operateTime"
                    >
                        {{ activity.description }}
                    </el-timeline-item>
                </el-timeline>
            </div>
        </pop-view>

        <!--支付页面-->
        <transition name="payFullscreen">
            <div
                    class="cardCardCheckoutView"
                    v-if="payView"
                    v-loading="payBtnLoading"
                    :element-loading-text="payBtnLoadingVal"
            >
                <div class="leftView" style="margin-right: 480px">
                    <div class="topView">
                        <label>支付</label>
                        <button class="btn-back" @click="handleCloseCardPay"></button>
                    </div>
                    <div class="contentView listView" style="height: 620px">
                        <div class="orderInfoView">
                            <div class="timeView">下单时间：2020-08-27 14:57</div>
                            <div class="toPayView">
                                <div class="amtItem subItem">
                                    <label class="label-name">
                    <span>
                      原价:￥{{ clothesOrderPrice.orderOriginalPrice }}
                    </span>
                                    </label>
                                    <label class="label-name">
                    <span>
                      优惠金额:￥{{ clothesOrderPrice.discountPrice }}
                    </span>
                                    </label>
                                    <label class="label-name">
                                        <span>运费:￥{{ clothesOrderPrice.fare }}</span>
                                    </label>
                                </div>
                                <div class="amtItem subItem">
                                    <label class="label-name">
                    <span>
                      折后总价: ￥{{ clothesOrderPrice.actuallyPaidPrice }}
                    </span>
                                    </label>
                                    <label class="label-name">
                    <span style="color: red">
                      实付金额:￥{{ clothesOrderPrice.actuallyPaidPrice }}
                    </span>
                                    </label>
                                    <label class="label-name" v-if="payType === 2">
                    <span style="color: red">
                      待补款:￥{{ clothesOrderPrice.waitPayPrice }}
                    </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="paymentView">
                            <div class="tView">支付方式</div>
                            <div class="listView">
                                <div
                                        class="paymentItem listItem"
                                        v-for="(item, index) in pay_ways"
                                        :key="index"
                                        :style="item.bool ? 'pointer-events: none' : ''"
                                        @click="handlePayWay(item)"
                                >
                                    <div class="iconView">
                                        <!--<img :src="item.ico">-->
                                        <label class="label-icon">{{ item.ico }}</label>
                                    </div>
                                    <div
                                            class="textView overflowText"
                                            v-if="item.name == '历史余额'"
                                    >
                                        {{ item.name }}({{
                                        userAccount.balance == null ? 0 : userAccount.balance
                                        }})
                                    </div>
                                    <div
                                            class="textView overflowText"
                                            v-else-if="item.name === '通享卡余额'"
                                    >
                                        {{ item.name }}({{
                                        userAccount.universalBalance == null
                                            ? 0
                                            : userAccount.universalBalance
                                        }})
                                    </div>
                                    <div class="textView overflowText" v-else>
                                        {{ item.name }}
                                    </div>
                                    <div class="selectView" v-if="payWay === item.label">
                                        <img
                                                src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_Black.png"
                                        />
                                    </div>
                                    <div
                                            class="selectView"
                                            style="background:#ccc;opacity: 0.4;"
                                            v-if="item.bool"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btnView">
                        <div class="btn-settle" @click="payBtn">结账</div>
                    </div>
                </div>
                <div class="rightView" style="width: 480px">
                    <div class="defImgView">
                        <img
                                src="https://static.bokao2o.com/wisdomDesk/images/Def_Bc_OrderCheckout_Right.png"
                        />
                        <p>{{ orderCheckoutVal }}</p>
                    </div>
                    <transition name="paidFullscreen">
                        <div
                                id="qrCodePayPopView"
                                class="popView"
                                style="width: 480px"
                                v-if="payWayView"
                                v-loading="qrCodePayView"
                                :element-loading-text="qrCodePayViewName"
                        >
                            <div class="popView-bcView"></div>
                            <div
                                    class="popView-contentView"
                                    style="width: 450px; top: 0; right: 0; overflow: auto"
                            >
                                <div class="qrCodePayView" style="height: 794px">
                                    <div class="contentView" style="height: 513px">
                                        <div class="amtView">
                                            ¥
                                            <span>{{ payPrice }}</span>
                                        </div>
                                        <div class="imgView">
                                            <img src="https://www.shouqianba.com/img/logo.svg"/>
                                        </div>
                                        <div class="qrCodeInputView">
                                            <el-input
                                                    class="input-scanPayCode"
                                                    placeholder="请扫描或输入付款码"
                                                    autocomplete="off"
                                                    ref="pay"
                                                    v-model="payVal"
                                                    type="number"
                                                    autofocus="autofocus"
                                            ></el-input>
                                            <button @click="handlePay">收款</button>
                                        </div>
                                        <div class="tipView">
                                            请使用支付宝/微信扫码支付
                                            <p>支付过程中，请不要关闭该二维码！</p>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn-close" @click="payWayView = false"></button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>

        <!-- 发起催单的弹窗 -->
        <!-- <el-dialog
          title="发送催单"
          :visible.sync="dialogVisible"
          @close="closeDialog"
          width="35%"
          append-to-body
        >
          <p><span>备注:</span></p>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="remarksText"
          ></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="send">确 定</el-button>
          </span>
        </el-dialog> -->
        <reminderPage
                :dialogVisible="dialogVisible"
                :addLoading="addLoading"
                @closeDialog="closeDialog"
                :reminderData="reminderData"
                :reminderList="reminderList"
                @refreshReminderList="refreshReminderList"
                :reminderDialogLoading="reminderDialogLoading"
        ></reminderPage>

        <!--美团支付弹框-->
        <el-dialog
                :visible.sync="dialogMeituanCousume"
                width="70%"
                :title="storeUserInfo.storeName"
                class="add-dialog"
                append-to-body
                v-loading="receiptCodePayView"
                :element-loading-text="receiptCodePayViewName"
        >
            <div>
                <div style="display:flex;justify-content: center;">
                    <div style="width: 30%">
                        <el-input
                                ref="receiptCode"
                                v-model="receiptCode"
                                placeholder="请输入团购券码信息"
                                @keyup.enter="scanCodeCoupon"
                        />
                    </div>
                    <div style="margin-left: 20px">
                        <el-button
                                type="primary"
                                :loading="codeLoading"
                                :disabled="receiptCode == ''"
                                @click="scanCodeCoupon"
                        >验券
                        </el-button
                        >
                    </div>
                </div>
                <!-- <div v-if="imgActive == false" style="height:120px"></div> -->
                <div v-if="imgActive">
                    <div class="crad-header">
                        <el-row>
                            <el-col class="discount ">
                                <div
                                        class="crad"
                                        :class="{ active: isActive }"
                                        @click="clickCategory(receiptInfo.packageId)"
                                >
                                    <img
                                            v-if="categoryIndex != ''"
                                            class="hook"
                                            src="../../../../assets/img/icon_Select.png"
                                            alt=""
                                    />
                                    <img class="imga" :src="receiptInfo.packageImage"/>
                                    <div class="crad-item">
                                        <div>
                                            <span>{{ receiptInfo.dealTitle }}</span>
                                        </div>
                                        <div>
                      <span
                              style="
                          word-break: keep-all;
                          color: red;
                          padding-right: 20px;
                        "
                      >{{ receiptInfo.dealPrice }}</span
                      >
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div>
                    <div class="receiptBth">
                        <el-button
                                type="primary"
                                style="margin-right: 50px"
                                :disabled="categoryIndex == 0"
                                @click="meituanSubmit"
                        >确认消费
                        </el-button>
                        <el-button
                                style="border: 1px solid #dcdfe6"
                                @click="handleCancelCousume"
                        >取消消费
                        </el-button
                        >
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import PopView from "@/components/PopView";
import reminderPage from "@/components/reminderPage";
import {
    getOrderPrice,
    payOrder,
    payOrderQuery,
    sendOrderRemin,
    cancelOrder,
} from "@/api/clothes/order";
import {
    clothesAccessoryList,
    inquireQuery,
    tikTok,
    setCashierTradeBalance,
} from "@/api/clothes/clothes";
import {queryReminder} from "@/api/factory/reminderOrder";
import {getUserAccount} from "@/api/member/user";
import {getLodop} from "@/print/LodopFuncs";
import {mapGetters} from "vuex";

export default {
    props: {
        orderDetailOption: {
            required: true,
            type: Object,
        },
        orderNumber: {
            type: [Number, String],
        },
    },
    components: {
        PopView,
        reminderPage,
    },

    data() {
        return {
            remarksText: "", //催单弹出层的备注信息
            dialogVisible: false,
            orderRemNumber: 0, //催单订单的数量
            dialogTableVisible: false,
            payStatus: "",
            payStatusOptions: [
                {
                    label: "未支付",
                    val: "1",
                },
                {
                    label: "已支付",
                    val: "2",
                },
            ],
            orderStatus: "",
            orderArr: [],
            loadingOrderArr: false,
            noMoreOrderArr: false,
            moreList: [],
            orderSource: "",
            // orderNumber: '',
            searchOrderNumber: "",
            orderInfoLoading: false,
            clothes: {},
            clothesStatusList: [],
            tabs: [
                {val: "info", title: "信息"},
                {val: "status", title: "状态"},
            ],
            currentTab: "info",
            //退款
            checkArr: [],
            showModal: false,
            orderClothesDetail: {},
            checkedClothesList: [],
            refundAmount: 0,
            // 支付
            payView: false,
            // 支付方式
            pay_ways: [
                {
                    name: "通享卡余额",
                    ico: "通",
                    label: 11,
                    bool: false,
                },
                {
                    name: "历史余额",
                    ico: "余",
                    label: 3,
                    bool: false,
                },
                {
                    name: "现金",
                    ico: "现",
                    label: 4,
                    bool: false,
                },
                {
                    name: "收钱吧",
                    ico: "收",
                    label: 6,
                    bool: false,
                },
                {
                    name: "美团/点评",
                    ico: "美",
                    label: 7,
                    bool: false,
                },
            ],
            payWay: "",
            payWayView: false,
            // 支付类型
            payType: 1,
            // 用户余额
            userAccount: {},
            // 衣物订单价格
            clothesOrderPrice: {},
            payVal: "",
            myInterval: null,
            shouInterval: null,
            // 付款码付款
            qrCodePayView: false,
            // 付款码提示
            qrCodePayViewName: "收款中...",
            payBtnLoading: false,
            payBtnLoadingVal: "收衣中...",
            outTradeNo: "",
            orderCheckoutVal: "请选择结账方式",
            payPrice: "",
            // 自定义高度
            screenHeight: document.documentElement.clientHeight,
            // 打印
            priCheckedClothesList: [],
            refundUserName: "",
            clothesNumList: [], //衣物详情中--选中时的衣物号
            isApplets: true,
            refundReason: "",
            detailView: false,

            // 美团
            dialogMeituanCousume: false,
            codeLoading: false,
            receiptCode: "",
            receiptInfo: {
                // appDealId: '1',
                // count: 0,
                // dealPrice: '1',
                // dealTitle: '1',
                // receiptCode: '',
            },
            receiptCodePayView: false,
            receiptCodePayViewName: "验券中...",
            receiptNum: 0,
            list: [],
            isActive: false,
            imgActive: false,
            categoryIndex: "",
            figure: 0,
            reminderData: {},
            addLoading: false,
            reminderDialogLoading: false,
            reminderList: {},
        };
    },
    computed: {
        ...mapGetters({
            storeUserInfo: "userInfo",
        }),
    },
    created() {
    },
    mounted() {
        const that = this;
        window.onresize = () => {
            return (() => {
                window.screenHeight = document.body.clientHeight;
                that.screenHeight = window.screenHeight;
                console.log(that.screenHeight);
            })();
        };
    },
    methods: {
        //催单弹框的关闭事件
        closeDialog() {
            this.dialogVisible = false;
            this.remarksText = "";
        },
        goBack() {
            this.$emit("diaLogIsShow", false);
        },
        goToReminder() {
            this.$router.push({
                path: "/home/more/reminders",
            });
        },
        // 发送催单
        async send() {
            if (this.remarksText.trim() == "") {
                return this.$message.error("请输入备注信息!");
            } else {
                const {data: res} = await sendOrderRemin({
                    clothesList: this.clothesNumList,
                    storeExplain: this.remarksText,
                });
                console.log(res);
                if (res.code !== 0) return this.$message.error(res.msg);
                this.priCheckedClothesList = [];
                this.clothesNumList = [];
                this.$message.success("您已成功发起催单!");
                this.remarksText = "";
                this.dialogVisible = false;
                // this.getRemNumber()
                this.wait = false;
                this.$parent.getClothesOrderDetails(this.orderNumber);
            }
        },
        //查询可催单的订单
        queryReminder() {
            this.dialogVisible = true;
            this.reminderDialogLoading = true;
            queryReminder(this.orderDetailOption.orderNumber).then((res) => {
                this.reminderData = res.data.data;
                this.reminderDialogLoading = false;
            });
        },
        refreshReminderList() {
            this.queryReminder();
            this.$emit("detailedInquiry", this.orderDetailOption.orderNumber);
        },
        // 打印小票
        printPaper() {
            this.$confirm("确认要打印小票嘛?", "系统提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
            })
                .then(() => {
                    let LODOP = getLodop();
                    LODOP.SET_LICENSES(
                        "",
                        "660FDFD7D9F7F7101A272F2EB57C306A",
                        "C94CEE276DB2187AE6B65D56B3FC2848",
                        ""
                    );
                    this.getPrint(1);
                    LODOP.PRINT_INIT("收银小票"); //首先一个初始化语句
                    LODOP.SET_PRINT_PAGESIZE(3, "75mm", "20mm", "收银小票");

                    let height = 0;
                    //公司名称
                    LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "洗衣通");
                    LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
                    LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                    height = height + 10;
                    //门店名称
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        this.orderDetailOption.storeName
                    );
                    LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
                    // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                    //订单号条形码
                    height = height + 6;
                    LODOP.ADD_PRINT_BARCODE(
                        height + "mm",
                        "14mm",
                        "55mm",
                        "9mm",
                        "128A",
                        this.orderDetailOption.orderNumber
                    );
                    LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
                    //订单号
                    height = height + 10;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "No." + this.orderDetailOption.orderNumber
                    );
                    LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                    LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                    // 线
                    height = height + 6;
                    LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);
                    height = height + 6;
                    //衣物信息标题
                    LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "衣物信息");
                    LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
                    LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                    height = height + 6;
                    //衣物列表：名称 服务 件数 原价 实收
                    if (this.orderDetailOption.clothesList.length > 0) {
                        LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "衣物号");
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                        LODOP.ADD_PRINT_TEXT(height + "mm", "16mm", "75mm", "4mm", "名称");
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                        LODOP.ADD_PRINT_TEXT(height + "mm", "29mm", "75mm", "4mm", "项目");
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                        LODOP.ADD_PRINT_TEXT(height + "mm", "40mm", "75mm", "4mm", "件数");
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                        LODOP.ADD_PRINT_TEXT(
                            height + "mm",
                            "50mm",
                            "75mm",
                            "4mm",
                            "原价/实收"
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                        height = height + 5;
                    }
                    this.printList = this.orderDetailOption.clothesList.filter(
                        (item) => item.accessoryNumber == null || item.accessoryNumber == ""
                    );
                    //衣物列表
                    for (let i = 0; i < this.printList.length; i++) {
                        let clothes = this.printList[i];
                        LODOP.ADD_PRINT_TEXT(
                            height + i * 5 + 1 + "mm",
                            "0mm",
                            "75mm",
                            "4mm",
                            clothes.outClothesNum
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

                        LODOP.ADD_PRINT_TEXT(
                            height + i * 5 + 1 + "mm",
                            "16mm",
                            "75mm",
                            "4mm",
                            clothes.clothesName.length > 3
                                ? clothes.clothesName.substring(0, 3) + "*"
                                : clothes.clothesName
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                        LODOP.ADD_PRINT_TEXT(
                            height + i * 5 + 1 + "mm",
                            "29mm",
                            "75mm",
                            "4mm",
                            clothes.clothesServiceName
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                        LODOP.ADD_PRINT_TEXT(
                            height + i * 5 + 1 + "mm",
                            "40mm",
                            "75mm",
                            "4mm",
                            clothes.num
                        ); //件数
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                        LODOP.ADD_PRINT_TEXT(
                            height + i * 5 + 1 + "mm",
                            "50mm",
                            "75mm",
                            "4mm",
                            clothes.originalPrice + "/" + clothes.currentPrice
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                        height = height;
                    }

                    height = height + this.printList.length * 5 + 1;

                    //注意：*衣物详细信息请进入洗衣通小程序查看*
                    // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "*衣物详细信息请进入洗衣通小程序查看*");
                    // LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
                    // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                    // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);

                    LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);
                    height = height + 6;
                    LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "总件数：");
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "60mm",
                        "75mm",
                        "4mm",
                        this.orderDetailOption.clothesNum
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "原价总额："
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "60mm",
                        "75mm",
                        "4mm",
                        "￥" + this.orderDetailOption.orderOriginalPrice
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "优惠总额："
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "60mm",
                        "75mm",
                        "4mm",
                        "￥" + this.orderDetailOption.discountPrice
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "折后总额："
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "60mm",
                        "75mm",
                        "4mm",
                        "￥" + this.orderDetailOption.actuallyPaidPrice
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    height = height + 5;
                    if (this.orderDetailOption.payWay) {
                        LODOP.ADD_PRINT_TEXT(
                            height + "mm",
                            "0mm",
                            "75mm",
                            "4mm",
                            this.orderDetailOption.payWay + "："
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                        LODOP.ADD_PRINT_TEXT(
                            height + "mm",
                            "60mm",
                            "75mm",
                            "4mm",
                            "￥" + this.orderDetailOption.actuallyPaidPrice
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                        height = height + 5;
                    }
                    // 线
                    LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

                    //收银信息
                    // height = height + 4;
                    // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "收银信息");
                    // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    // LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
                    // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

                    //收银详情
                    height = height + 6;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "客户姓名：" + this.orderDetailOption.orderRealName
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "联系电话：" + this.orderDetailOption.orderUserPhone
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    // 充值
                    if (this.orderDetailOption.rechargeFlag === 2) {
                        if (
                            this.orderDetailOption.cardNo === "" ||
                            this.orderDetailOption.cardNo === null
                        ) {
                            height = height + 5;
                            LODOP.ADD_PRINT_TEXT(
                                height + "mm",
                                "0mm",
                                "75mm",
                                "4mm",
                                "会员卡号：" + "-"
                            );
                            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                        } else {
                            height = height + 5;
                            LODOP.ADD_PRINT_TEXT(
                                height + "mm",
                                "0mm",
                                "75mm",
                                "4mm",
                                "会员卡号：" + this.orderDetailOption.cardNo
                            );
                            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                        }

                        height = height + 5;
                        LODOP.ADD_PRINT_TEXT(
                            height + "mm",
                            "0mm",
                            "75mm",
                            "4mm",
                            "卡内余额：" + this.orderDetailOption.balance
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    }
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "现金券余额：" + this.orderDetailOption.cashCouponBalance
                    );
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "收衣店长：" + this.orderDetailOption.storeUserName
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    height = height + 5;
                    // LODOP.ADD_PRINT_TEXT(height + "mm", "37.5mm", "75mm", "4mm", "取衣时间：" + this.orderDetailOption.takeTime);
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "取衣时间：请咨询店长"
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "收衣时间：" + this.orderDetailOption.orderTime
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    //门店电话
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "3mm",
                        "门店电话：" + this.orderDetailOption.store.phone
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    //门店地址
                    if (this.orderDetailOption.store.address.length > 16) {
                        height = height + 5;
                        LODOP.ADD_PRINT_TEXT(
                            height + "mm",
                            "0mm",
                            "75mm",
                            "3mm",
                            "门店地址：" +
                            this.orderDetailOption.store.address.substring(0, 16)
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                        height = height + 5;
                        LODOP.ADD_PRINT_TEXT(
                            height + "mm",
                            "0mm",
                            "75mm",
                            "3mm",
                            this.orderDetailOption.store.address.substring(16)
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    } else {
                        height = height + 5;
                        LODOP.ADD_PRINT_TEXT(
                            height + "mm",
                            "0mm",
                            "75mm",
                            "3mm",
                            "门店地址：" + this.orderDetailOption.store.address
                        );
                        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                    }
                    //线
                    height = height + 6;
                    // 线
                    LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

                    //二维码
                    height = height + 5;
                    LODOP.ADD_PRINT_BARCODE(
                        height + "mm",
                        "25mm",
                        "30mm",
                        "30mm",
                        "QRCode",
                        "http://weixin.qq.com/r/QxBIUJzE6sGVrdgz90VR"
                    );
                    height = height + 25;

                    //注：扫码进入小程序查看衣物详细信息
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "扫码进入公众号查看衣物订单详细信息"
                    );
                    LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
                    //请妥善保管取衣凭证，如丢失请立即前往门店挂失
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "请妥善保管取衣凭证，如丢失请立即前往门店挂失"
                    );
                    LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
                    LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

                    //投诉服务电话：400-816-5008
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "15mm",
                        "75mm",
                        "4mm",
                        "投诉服务电话：400-816-5008"
                    );
                    // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "55mm",
                        "75mm",
                        "4mm",
                        "#" + this.orderDetailOption.printNum
                    );
                    // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 15);
                    LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

                    LODOP.SET_PRINTER_INDEX(this.printIndex);
                    LODOP.PRINT();
                    // LODOP.PREVIEW();
                })
                .catch(() => {
                });
        },

        // 打印标签
        printLabel() {
            if (this.priCheckedClothesList.length === 0) {
                this.$message.error("请选择要打印的衣物");
                return false;
            }
            this.$confirm("确认要打印水洗唛嘛?", "系统提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
            })
                .then(() => {
                    let list = [];
                    for (let i = 0; i < this.orderDetailOption.clothesList.length; i++) {
                        for (let j = 0; j < this.priCheckedClothesList.length; j++) {
                            if (
                                this.orderDetailOption.clothesList[i].id ===
                                this.priCheckedClothesList[j]
                            ) {
                                list.push(this.orderDetailOption.clothesList[i]);
                            }
                        }
                    }
                    console.log(list);
                    let LODOP = getLodop();
                    LODOP.SET_LICENSES(
                        "",
                        "660FDFD7D9F7F7101A272F2EB57C306A",
                        "C94CEE276DB2187AE6B65D56B3FC2848",
                        ""
                    );
                    this.getPrint(2);

                    for (let i = 0; i < list.length; i++) {
                        let clothes = list[i];
                        console.log(clothes);
                        LODOP.PRINT_INIT("水洗唛"); //首先一个初始化语句
                        LODOP.SET_PRINT_PAGESIZE(1, "110mm", "23mm", "");
                        //一边30mm,65mm
                        LODOP.ADD_PRINT_TEXT(
                            "0mm",
                            "7mm",
                            "70mm",
                            "10mm",
                            "品类：" +
                            " " +
                            clothes.clothesName +
                            " " +
                            clothes.clothesServiceName
                        ); //然后多个ADD语句及SET语句  打印附件

                        LODOP.ADD_PRINT_TEXT(
                            "0mm",
                            "65mm",
                            "33mm",
                            "10mm",
                            this.orderDetailOption.storeName + (clothes.suspensionNumber?'('+clothes.suspensionNumber+')':'')
                        ); //然后多个ADD语句及SET语句
                        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                        LODOP.ADD_PRINT_TEXT(
                            "4mm",
                            "7mm",
                            "70mm",
                            "10mm",
                            "订单：" +
                            this.orderDetailOption.orderNumber +
                            " " +
                            this.orderDetailOption.orderTime
                        ); //然后多个ADD语句及SET语句
                        LODOP.ADD_PRINT_TEXT(
                            "8mm",
                            "7mm",
                            "70mm",
                            "10mm",
                            "附件：" + clothes.clothesAccessory
                        ); //然后多个ADD语句及SET语句v
                        //条形码
                        LODOP.ADD_PRINT_BARCODE(
                            "5mm",
                            "70mm",
                            "33mm",
                            "10mm",
                            "128C",
                            clothes.outClothesNum
                        );
                        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                        LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
                        let desc = "";
                        if (clothes.colorName !== "") {
                            desc = desc + clothes.colorName + " ";
                        }
                        if (clothes.brandName !== "") {
                            desc = desc + clothes.brandName + " ";
                        }
                        if (clothes.effects !== "") {
                            desc = desc + clothes.effects + " ";
                        }
                        if (clothes.blemishes !== "") {
                            desc = desc + clothes.blemishes;
                        }

                        LODOP.ADD_PRINT_TEXT(
                            "12mm",
                            "7mm",
                            "65mm",
                            "10mm",
                            "描述：" + desc
                        ); //然后多个ADD语句及SET语句
                        LODOP.ADD_PRINT_TEXT(
                            "15mm",
                            "65mm",
                            "38mm",
                            "10mm",
                            clothes.outClothesNum
                        ); //然后多个ADD语句及SET语句
                        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                        LODOP.SET_PRINTER_INDEX(this.printIndex);
                        LODOP.PRINT();
                    }
                })
                .catch(() => {});
        },

        // 取消订单
        cancelOrder(orderNumber) {
            this.$prompt("请输入取消原因", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(({value}) => {
                console.log(value);
                let data = {
                    orderNumber: orderNumber,
                    cancelOrderReason: value,
                };
                cancelOrder(data).then((response) => {
                    if (response.data.code === 0) {
                        this.$message.success("取消订单成功");
                        this.$parent.getClothesOrderDetails(this.orderNumber);
                    } else {
                        this.$message.error(response.data.msg);
                    }
                });
            });
        },

        // 查询订单金额
        handlePayModal() {
            if (this.orderDetailOption.orderStatus === "已取消") {
                this.$message.error("订单已取消，无法支付");
                return;
            }
            if (this.orderDetailOption.orderSource === "美团订单") {
                this.pay_ways = [
                    {name: "美团/点评", ico: "美", label: 7, bool: false},
                ];
            } else if (this.orderDetailOption.orderSource === "抖音订单") {
                this.pay_ways = [
                    {name: "抖音支付", ico: "抖", label: 10, bool: false},
                ];
            } else {
                this.pay_ways = this.pay_ways.filter((item) => {
                    if (item.label != 7 && item.label != 10) {
                        return item;
                    }
                });
            }
            // 打开支付弹框
            this.payView = true;
            // 查询订单
            this.getOrderPriceList();
        },
        // 查询订单
        getOrderPriceList() {
            getOrderPrice(this.orderNumber).then((res) => {
                this.clothesOrderPrice = res.data.data;
                console.log(this.clothesOrderPrice.payWay, "99999");
                this.payPrice = res.data.data.actuallyPaidPrice;
                getUserAccount(res.data.data.userId).then((response) => {
                    console.log(response);
                    this.userAccount = response.data.data;
                    this.pay_ways = this.pay_ways.map((item) => {
                        if (item.name == "通享卡余额") {
                            if (
                                this.userAccount.universalBalance == null ||
                                this.userAccount.universalBalance == 0
                            ) {
                                item.bool = true;
                                return item;
                            } else {
                                item.bool = false;
                            }
                            if (this.userAccount.balance > 0) {
                                item.bool = true;
                            } else {
                                item.bool = false;
                            }
                        }
                        if (item.name == "历史余额") {
                            if (this.userAccount.balance == null) {
                                item.bool = true;
                                return item;
                            } else {
                                item.bool = false;
                            }
                            if (this.userAccount.balance == 0) {
                                item.bool = true;
                            } else {
                                item.bool = false;
                            }
                        }
                        return item;
                    });
                });
            });
        },
        // 查询支付结果
        manualPayResult(outTradeNo) {
            if (this.orderDetailOption.orderStatus === "已取消") {
                this.$message.error("订单已取消，无法查询");
                return;
            }
            if (this.orderDetailOption.payWay === "收钱吧") {
                this.figure = 6;
            }
            if (this.orderDetailOption.orderSource === "美团订单") {
                this.figure = 7;
            }
            if (this.orderDetailOption.orderSource === "抖音订单") {
                this.figure = 10;
            }
            const params = new URLSearchParams();
            params.append("payWay", this.figure);
            params.append("outTradeNo", outTradeNo);
            params.append("orderNumber", this.orderNumber);
            payOrderQuery(params).then((response) => {
                console.log(response);
                // 抖音
                if (this.figure == 10) {
                    if (response.data.code != 0) {
                        this.$message.error(response.data.msg);
                    } else {
                        this.commonPaySuccess();
                    }
                } else {
                    if (response.data.data.success) {
                        if (response.data.data.tradeState === "SUCCESS") {
                            this.commonPaySuccess();
                        } else if (response.data.data.subCode === "SUCCESS") {
                            this.commonPaySuccess();
                        } else {
                            this.$message.error("等待用户支付!");
                        }
                    } else {
                        if (response.data.data.msg) {
                            this.$message.error(response.data.data.msg);
                        } else {
                            this.$message.error(response.data.data.subMsg);
                        }
                    }
                }
            });
        },

        // 差额退补支付
        handleDiffPayModal() {
            if (this.orderDetailOption.orderStatus === "已取消") {
                this.$message.error("订单已取消，无法支付");
                return;
            }
            // 打开支付弹框
            this.payView = true;
            // 查询订单
            getOrderPrice(this.orderNumber).then((res) => {
                this.clothesOrderPrice = res.data.data;
                this.payType = res.data.data.updateFlag;
                this.payPrice = res.data.data.waitPayPrice;
                getUserAccount(res.data.data.userId).then((response) => {
                    this.userAccount = response.data.data;
                });
            });
        },
        showDialogDetail(item) {
            let _this = this;
            _this.clothesStatusList = [];
            _this.clothes = item;
            console.log(_this.clothes.clothesStatusList);
            _this.clothes.clothesStatusList.forEach(function (item, index) {
                if (item.active) {
                    _this.clothesStatusList.push(item);
                }
            });
            this.detailView = true;
        },
        //点击发送催单按钮
        sendOrderRem() {
            if (this.clothesNumList.length === 0) {
                return this.$message.error("请选择您要发起催单的衣物!");
            }
            this.dialogVisible = true;
        },
        changePriCheck(value, item) {
            if (value.indexOf("-") === -1) {
                this.priCheckedClothesList.push(value);
                this.clothesNumList.push(item.clothesNum);
            } else {
                this.priCheckedClothesList = this.priCheckedClothesList.filter(
                    (i) => i !== value.slice(1)
                );
                this.clothesNumList.forEach((item2, index) => {
                    return this.clothesNumList.splice(index, 1);
                });
            }
            console.log(this.clothesNumList);
        },
        // 修改
        updateClothes(item) {
            // 修改前判断订单状态  待收衣、已收衣、已送洗、已入厂、清洗中
            let arr = ["待收衣", "已收衣", "已送洗", "已入厂", "清洗中"];
            if (arr.indexOf(this.orderDetailOption.orderStatus) == -1) {
                this.$message.error("当前订单状态不支持修改衣物信息");
                return false;
            }
            this.$confirm("确认要去修改衣物?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(async () => {
                let data = {};
                data.flag = "update";
                data.orderNumber = item.orderNumber;
                data.userId = this.orderDetailOption.userId;
                data.phone = this.orderDetailOption.userPhone;
                data.name = this.orderDetailOption.userName;
                data.address = this.orderDetailOption.userAddress;
                data.userType = this.orderDetailOption.userType;
                data.oneClickOrder = this.orderDetailOption.oneClickOrder;
                data.selectedClothes = [];

                let clothes = {};
                clothes.categoryId = item.categoryId;
                clothes.clothesId = item.clothesId;
                clothes.clothesNum = item.clothesNum;
                clothes.clothesName = item.clothesName;
                clothes.clothingType = item.clothesName;
                clothes.washMode = item.clothesServiceName;
                clothes.realPrice = item.currentPrice;
                clothes.num = item.num;
                clothes.remark = item.remark;
                let service = {};
                service.serviceId = item.serviceId;
                service.serviceName = item.clothesServiceName;
                service.sellPrice = item.originalPrice;
                service.vipPrice = item.currentPrice;
                clothes.service = service;

                if (item.brandName) {
                    let brand = {};
                    brand.brandId = item.brandId;
                    brand.brandName = item.brandName;
                    clothes.brand = brand;
                }

                if (item.clothesId) {
                    let color = {};
                    color.colorId = item.colorId;
                    color.colorName = item.colorName;
                    clothes.color = color;
                }

                if (item.blemishes) {
                    let blemishList = [];
                    let blemishs = item.blemishes.split(",");
                    let blemishIds = item.blemishIds.split(",");
                    for (let i = 0; i < blemishs.length; i++) {
                        let blemish = {};
                        blemish.blemishId = parseInt(blemishIds[i]);
                        blemish.blemishName = blemishs[i];
                        blemishList.push(blemish);
                    }
                    clothes.blemish = blemishList;
                }

                if (item.effects) {
                    let effectsList = [];
                    let effects = item.effects.split(",");
                    let effectIds = item.effectIds.split(",");
                    for (let i = 0; i < effects.length; i++) {
                        let effect = {};
                        effect.effectId = parseInt(effectIds[i]);
                        effect.effectName = effects[i];
                        effectsList.push(effect);
                    }
                    clothes.effects = effectsList;
                }
                // 获取附件
                let res = await clothesAccessoryList(item.clothesNum);
                clothes.accessory = res.data.data;

                clothes.photos = item.photos === null ? [] : item.photos;
                data.selectedClothes.push(clothes);

                let dataa = JSON.stringify(data);
                console.log(dataa);
                this.$router.push({path: "/get/types", query: {data: dataa}});
            });
        },

        handleDetailTab(val) {
            this.currentTab = val;
        },
        getPrint(type) {
            let defaultName;
            let LODOP = getLodop();
            LODOP.SET_LICENSES(
                "",
                "660FDFD7D9F7F7101A272F2EB57C306A",
                "C94CEE276DB2187AE6B65D56B3FC2848",
                ""
            );

            let arr = [];
            let iPrinterCount = LODOP.GET_PRINTER_COUNT();
            for (let i = 0; i < iPrinterCount; i++) {
                arr.push({
                    label: LODOP.GET_PRINTER_NAME(i),
                    value: i,
                });
            }

            let labelList = [];
            if (type === 1) {
                //小票
                defaultName = LODOP.GET_PRINTER_NAME("-1"); //GET_PRINTER_NAME(intPrinterIndex);用序号获得打印机名，一般序号从0开始，-1特指默认打印机；
                arr.forEach((item) => {
                    if (item.label === defaultName) {
                        this.printIndex = item.value;
                    }
                });
            } else {
                //水洗唛
                labelList.push("TSC");
                labelList.push("水洗唛打印机");
                labelList.push("HPRT XT300");
                labelList.push("HPRT XT300 - ZPL");
                arr.forEach((item) => {
                    for (let i = 0; i < labelList.length; i++) {
                        if (item.label === labelList[i]) {
                            console.log(labelList[i]);
                            this.printIndex = item.value;
                        }
                    }
                });
            }
        },

        // 处理点击支付
        handlePayWay(item) {
            console.log(this.orderDetailOption, "是啥");
            this.payWayView = false;
            this.payWay = item.label;
            this.clothes.payWay = item.label;
            if (item.label === 6) {
                this.payWayView = true;
                this.$nextTick(() => {
                    this.$refs.pay.focus();
                });
            } else if (item.label === 7 || item.label === 10) {
                this.dialogMeituanCousume = true;
                this.receiptInfo = {};
                this.imgActive = false;
                this.receiptCode = "";
                this.$nextTick(() => {
                    this.$refs.receiptCode.focus();
                });
            } else {
                this.orderCheckoutVal = "请使用" + item.name + "收款: " + this.payPrice;
            }
        },
        // 查询美团券信息
        async scanCodeCoupon() {
            if (this.orderDetailOption.orderSource === "美团订单") {
                this.codeLoading = true;
                await inquireQuery(
                    this.receiptCode,
                    this.orderDetailOption.orderUserPhone
                )
                    .then((res) => {
                        if (res.data.code == 0) {
                            // 组装数据
                            this.imgActive = true;
                            this.receiptInfo = res.data.data;
                            this.codeLoading = false;
                        } else {
                            this.codeLoading = false;
                            return this.$message.error(res.data.msg);
                        }
                    })
                    .catch(() => {
                        this.codeLoading = false;
                    });
            } else {
                let params = {
                    couponCode: this.receiptCode,
                    userPhone: this.orderDetailOption.orderUserPhone,
                };
                this.codeLoading = true;
                await tikTok(params)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.imgActive = true;
                            this.receiptInfo = res.data.data;
                            this.codeLoading = false;
                        } else {
                            this.codeLoading = false;
                            return this.$message.error(res.data.msg);
                        }
                    })
                    .catch(() => {
                        this.codeLoading = false;
                    });
            }
        },
        clickCategory(index) {
            // 这里我们传入一个当前值
            this.categoryIndex = index;
            this.isActive = true;
        },
        // 取消消费
        handleCancelCousume() {
            this.receiptInfo = {};
            this.imgActive = false;
            this.receiptCode = "";
        },
        meituanSubmit() {
            this.receiptCodePayView = true;
            if (this.receiptInfo.receiptCode) {
                this.payVal = this.receiptInfo.receiptCode;
                this.payOrder();
            } else {
                this.receiptCodePayView = false;
                this.$message.error("请输入券码");
            }
        },
        // 关闭支付页面
        handleCloseCardPay() {
            this.payView = false;
            this.payWay = "";
            this.clothes.payWay = false;
            this.payWayView = false;
        },
        // 支付按钮
        payBtn() {
            if (!this.clothes.payWay) {
                this.$message.error("请选择支付方式!");
                return false;
            }
            // 如果选择了余额或者洗后付或现金 不进行弹框
            if (
                this.clothes.payWay === 1 ||
                this.clothes.payWay === 2 ||
                this.clothes.payWay === 6
            ) {
                if (this.choosePaywayIndex !== 0) this.payWay = true;
                this.$nextTick(() => {
                    this.$refs.pay.focus();
                });
                // this.qrCodePayView = true
                // this.payOrder()
            } else if (this.clothes.payWay === 7) {
                if (this.choosePaywayIndex !== 0) this.dialogMeituanCousume = true;
                this.$nextTick(() => {
                    this.$refs.receiptCode.focus();
                });
            }
            if (this.clothes.payWay === 11) {
                if (
                    this.userAccount.universalBalance >=
                    this.clothesOrderPrice.waitPayPrice
                ) {
                    this.payBtnLoading = true;
                    this.payOrder();
                } else {
                    this.$message.error("通享卡不足无法支付");
                }
            } else {
                // 余额
                if (this.clothes.payWay === 3) {
                    if (this.payType === 2) {
                        // 付款金额与余额比较
                        if (
                            this.userAccount.balance >= this.clothesOrderPrice.waitPayPrice
                        ) {
                            console.log("差额补余额支付");
                            if (this.payType === 2) {
                                this.payVal = "DIFF";
                            }
                            this.payBtnLoading = true;
                            this.payOrder();
                        } else {
                            this.$message.error("余额不足无法支付");
                        }
                    } else {
                        // 付款金额与余额比较
                        if (
                            this.userAccount.balance >=
                            this.clothesOrderPrice.actuallyPaidPrice
                        ) {
                            this.payBtnLoading = true;
                            this.payOrder();
                        } else {
                            console.log(this.orderDetailOption);
                            this.$confirm(
                                "历史余额不足，无支付本笔订单，是否转余额?",
                                "提示",
                                {
                                    confirmButtonText: "确定",
                                    cancelButtonText: "取消",
                                    type: "warning",
                                }
                            )
                                .then(() => {
                                    console.log(this.storeUserInfo);
                                    let {
                                        userId,
                                        userName,
                                        userPhone,
                                        store: {storeId},
                                    } = this.orderDetailOption;
                                    setCashierTradeBalance({
                                        userId,
                                        userName,
                                        phone: userPhone,
                                        storeId,
                                    }).then(({data}) => {
                                        if (data.code == 0) {
                                            this.$message.success("转余额成功");
                                            this.getOrderPriceList();
                                            this.payWay = null;
                                        }
                                    });
                                })
                                .catch(() => {
                                    this.$message({
                                        type: "info",
                                        message: "已取消转余额",
                                    });
                                });
                        }
                    }
                }

                if (this.clothes.payWay === 4 || this.clothes.payWay === 5) {
                    if (this.payType === 2) {
                        this.payVal = "DIFF";
                    }
                    this.payBtnLoading = true;
                    this.payOrder();
                }
            }
        },
        handlePay() {
            if (this.payVal) {
                this.qrCodePayView = true;
                this.payOrder();
            } else {
                this.qrCodePayView = false;
                this.$message.error("请读入或输入付款码");
            }
        },
        // 支付订单
        payOrder() {
            let payWay = this.clothes.payWay;
            let orderNumber = this.orderNumber;
            let authCode = this.payVal;
            let data = {
                payWay,
                orderNumber,
                authCode,
            };
            payOrder(data)
                .then((response) => {
                    console.log(response);
                    if (response.data.code === 0) {
                        this.receiptCodePayView = false;
                        this.dialogMeituanCousume = false;
                        // 只有收钱吧有回调
                        if (this.clothes.payWay === 6) {
                            this.outTradeNo = response.data.data.outTradeNo;
                            this.myInterval = setInterval(() => {
                                this.queryPayResult(this.outTradeNo); //调用接口的方法
                            }, 3000);
                        } else {
                            this.commonPaySuccess();
                        }
                    } else {
                        if (this.clothes.payWay === 6) {
                            clearInterval(this.shouInterval);
                            // 需要用户支付
                            if (response.data.data.errCode === "USERPAYING") {
                                console.log(response);
                                this.outTradeNo = response.data.data.outTradeNo;
                                this.myInterval = setInterval(() => {
                                    this.queryPayResult(this.outTradeNo); //调用接口的方法
                                }, 3000);
                            } else {
                                console.log(response);
                                this.$message.error(response.data.data.msg);
                                this.payBtnLoading = false;
                                this.closePayModal();
                            }
                        } else if (
                            this.clothes.payWay === 7 ||
                            this.clothes.payWay === 10
                        ) {
                            this.$message.error(response.data.data.msg);
                            this.receiptCodePayView = false;
                        } else {
                            this.$message.error(response.data.data.msg);
                            this.closePayModal();
                        }
                        this.qrCodePayView = false;
                    }
                })
                .catch((reason) => {
                    this.payBtnLoading = false;
                    this.qrCodePayView = false;
                    // 要进行跳转到当前未支付的订单
                    console.log(reason);
                    this.closePayModal();
                });
            if (this.clothes.payWay === 6) {
                this.payBtnLoadingVal = "等待用户输入密码!";
            }
        },
        // 查询支付结果
        queryPayResult(outTradeNo) {
            if (this.clothes.payWay === 6) {
                clearInterval(this.shouInterval);
            }
            const params = new URLSearchParams();
            params.append("payWay", this.clothes.payWay);
            params.append("outTradeNo", outTradeNo);
            params.append("orderNumber", this.orderNumber);
            payOrderQuery(params).then((response) => {
                console.log(response, 1111);

                // 微信
                if (response.data.data.success) {
                    if (response.data.data.tradeState === "SUCCESS") {
                        this.commonPaySuccess();
                    } else if (response.data.data.tradeState === "PAYERROR") {
                        this.payBtnLoading = false;
                        this.$message.error("用户支付失败,请重新扫码支付");
                        this.closePayModal();
                    } else {
                        this.payBtnLoadingVal = "等待用户输入密码!";
                    }
                } else {
                    this.payBtnLoading = false;
                    this.$message.error("用户支付失败,请重新扫码支付");
                    this.closePayModal();
                }
            });
        },
        // 公共的
        commonPaySuccess() {
            this.payBtnLoading = false;
            this.payView = false;
            this.payWayView = false;
            this.$message.success("支付成功");
            clearInterval(this.myInterval);
            this.$parent.getClothesOrderDetails(this.orderNumber);
        },
        // 关闭支付框
        closePayModal() {
            if (this.orderNumber) {
                clearInterval(this.myInterval);
                clearInterval(this.shouInterval);
                this.$parent.getClothesOrderDetails(this.orderNumber);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

.el-button {
  background: #3370ff;
  border: none;
}

.crad-header {
  position: relative;
  text-align: center;

  .discount {
    margin: 40px 0;
  }

  .crad {
    position: relative;
    padding: 20px 0;
    overflow: auto;
    width: 300px;
    height: 120px;
    border: 1.5px solid #78bce0;
    margin: 0 auto;

    .hook {
      position: absolute;
      left: 43%;
      top: 30%;
    }

    .crad-item {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .imga {
      width: 200px;
      height: 80px;
    }
  }

  .active {
    margin: 0 auto;
    width: 300px;
    height: 120px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #78bce0;
  }
}

.memberDetailView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  padding: 120px 20px 25px 25px;
  z-index: 100;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 90px;*/
    padding: 20px 25px 0 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        background-color: #fff;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .menuView {
        flex: 1;
        height: 60px;
        line-height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 0 100px 0 160px; */
        /*> /deep/ .el-tabs__nav-wrap::after {*/
        /*    position: static !important;*/
        /*}*/

        .menuItem {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          padding: 0 25px;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333;

          > .select {
            position: relative;
            color: #3370ff;
          }

          > .select:after {
            content: "";
            left: 0;
            right: 0;
            bottom: -8px;
            position: absolute;
            width: 24px;
            height: 2px;
            background: #3370ff;
            margin: auto;
            border-radius: 1px;
          }
        }
      }
    }
  }
}

.clothesInfo-contentView {
  > div {
    > .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 80px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }

  > .contentView {
    > div {
      padding-left: 20px;
      padding-right: 20px;
      line-height: 20px;
    }

    > .tView {
      position: relative;
      background: #f8f8f8;
      padding: 15px 20px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium;
      color: #333;
      overflow: hidden;

      .el-button {
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }

    .cashContentView {
      max-height: 300px;
      overflow: auto;
    }

    > .cashContentView .flexView {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 14px;
      color: #333;
      // line-height: 20px;
      padding: 6px 0;

      label:nth-child(1) {
        // flex: 0.2;
        text-align: center;
      }

      label {
        flex: 1;
        cursor: pointer;
        text-align: center;
      }
    }
  }

  > .payInfoView {
    padding-bottom: 25px;

    > div {
      display: flex;
      color: #333;
      line-height: 20px;
      font-size: 14px;
      padding: 6px 20px 6px 20px;
    }

    > div .label-leftText {
      width: 80px;
      min-width: 80px;
      color: #999;
    }

    > div .label-amt {
      display: inline-block;
      width: 200px;
      margin-top: 10px;
      color: #ff5e56;
      font-family: DINAlternate-Bold;
    }

    > div {
      > div {
        margin-top: 10px;
      }

      > div.payTypeView {
        margin-left: 20px;

        .payTypeItem {
          margin-top: 10px;
        }

        .label-name {
          display: inline-block;
          width: 200px;
          color: #ff5e56;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 0.9;
          font-family: DINAlternate-Bold;
        }
      }

      > div.remarkView {
        margin-left: 20px;

        .remarkCtn {
          margin-top: 10px;
          display: flex;
        }

        .label-leftText {
          width: 100px;
        }

        .remark-box {
          // display: inline-block;
          width: 200px;
        }
      }
    }

    > .tView {
      position: relative;
      background: #f8f8f8;
      padding: 15px 20px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium;
      color: #333;
      overflow: hidden;

      > button {
        position: absolute;
        top: 0;
        right: 20px;
        line-height: 50px;
        height: 50px;
        background: transparent;
        font-size: 14px;
        padding: 0;
        color: #3370ff;
      }
    }
  }
}

#order-detailViewPopView > .popView-contentView {
  > .menuView {
    position: absolute;
    width: 88px;
    display: flex;
    top: 45px;
    left: 0;
    right: 0;
    margin: auto;

    > button {
      font-size: 16px;
      color: #333;
      flex: 1;
      line-height: 30px;
      height: 30px;
      padding: 0;
    }

    > button.select {
      position: relative;
      color: #3370ff;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
    }

    > button.select:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0px;
      position: absolute;
      width: 24px;
      height: 2px;
      background: #3370ff;
      margin: auto;
      border-radius: 1px;
    }
  }

  > .contentView {
    .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 99px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }
}

#refundViewPopView > .popView-contentView > .contentView {
  padding: 0 15px;

  > .topView {
    background: #fff;
    padding: 10px 0;
    display: flex;

    > div {
      width: 145px;
      margin-right: 20px;
    }

    > div:nth-child(1) {
      margin-left: 30px;
    }
  }

  > .headerView {
    display: flex;
    text-align: center;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    color: #999;

    > div {
      flex: 1;
    }

    > div:nth-child(1) {
      flex: 0.5;
    }
  }

  > .listView {
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    padding-bottom: 25px;

    > .listItem {
      position: relative;
      display: flex;
      padding: 10px 0;
      line-height: 30px;
      font-size: 14px;
      color: #333;
      background: #fff;

      > div {
        flex: 1;

        > .btn-select {
          width: 60px;
          height: 100%;
          background: transparent url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png) center / 28px no-repeat;
        }

        .btn-select.select {
          background: transparent url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png) center / 28px no-repeat;
        }
      }

      > div:nth-child(1) {
        flex: 0.5;
      }
    }
  }

  > .btnView {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

//动画过程
.payFullscreen-enter {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

.payFullscreen-enter-active {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave-active {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes payFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes payFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}

//动画过程
.chooseCard-enter {
  animation: chooseCard-dialog-fade-in 0.9s ease;
}

.chooseCard-leave {
  animation: chooseCard-dialog-fade-out 0.9s ease forwards;
}

.chooseCard-enter-active {
  animation: chooseCard-dialog-fade-in 0.9s ease;
}

.chooseCard-leave-active {
  animation: chooseCard-dialog-fade-out 0.9s ease forwards;
}

@keyframes chooseCard-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes chooseCard-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.cardCardCheckoutView {
  position: fixed;
  display: flex;
  top: 0;
  /*left: 0;*/
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  z-index: 100;

  button {
    border: none;
    outline: none;
    box-sizing: border-box;
  }

  > .leftView {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;

    > .topView {
      position: relative;
      background: #fff;
      padding: 25px 0 15px 0;
      text-align: center;

      label {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #333;
        line-height: 40px;
      }

      > .btn-back {
        position: absolute;
        top: 25px;
        left: 15px;
        width: 40px;
        height: 40px;
        background-image: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_NavBack.png);
        background-position: left center;
        background-size: 28px;
        background-repeat: no-repeat;
      }

      button {
        border: none;
        outline: none;
        box-sizing: border-box;
        background-color: #fff;
      }
    }

    > .contentView {
      overflow-x: hidden;
      overflow-y: auto;

      > .orderInfoView .subItem {
        display: flex;
        padding: 12px 25px 12px 20px;
        line-height: 20px;

        > label {
          flex: 1;
          height: 20px;
        }

        > .label-name {
          flex: 1;
          color: #666;

          > span {
            font-family: PingFangSC-Semibold;
            font-size: 15px;
            color: #333;
            margin-right: 15px;
          }
        }

        > .label-amt {
          text-align: right;
          font-size: 15px;
          color: #333;
          font-family: DINAlternate-Bold;
        }
      }

      > .orderInfoView {
        padding: 0 30px 15px 30px;

        > .timeView {
          line-height: 30px;
          font-size: 14px;
          color: #666;
        }

        > .cardView {
          line-height: 32px;
          color: #333;
          font-size: 14px;

          > label {
            margin-right: 35px;
          }

          > label:last-child {
            margin-right: 0;
          }
        }

        > .toPayView {
          padding: 5px 0;
          margin-top: 15px;
          background: #f8f8f8;
          border-radius: 6px;
          overflow: hidden;
        }
      }

      > .paymentView {
        padding: 0 24px 0 24px;

        > .tView {
          margin: 0 6px;
          line-height: 40px;
          font-size: 15px;
          font-family: PingFangSC-Semibold;
          color: #333;
          border-top: 0.5px solid #ddd;
        }

        > .listView {
          overflow: hidden;

          > .paymentItem {
            position: relative;
            float: left;
            width: 100px;
            height: 100px;
            border-radius: 6px;
            background: #f8f8f8;
            padding: 10px 0;
            text-align: center;
            margin: 0 6px 10px 6px;
            overflow: hidden;

            > .iconView {
              height: 40px;

              > img {
                width: 40px;
                height: 40px;
              }

              > .label-icon {
                display: inline-block;
                border-radius: 6px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                font-family: PingFangSC-Medium;
                color: #fff;
                text-align: center;
                background: rgba(66, 153, 255, 0.7);
              }
            }

            > .textView {
              margin-top: 8px;
              line-height: 20px;
              font-size: 14px;
              color: #333;
            }

            > .selectView {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(40, 40, 45, 0.4);
              text-align: center;
              border-radius: 6px;

              > img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }

    > .btnView {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding: 20px 0;
      text-align: center;
      background: #fff;

      > .btn-settle {
        display: inline-block;
        width: 260px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        background: #3370ff;
        border-radius: 6px;
      }
    }
  }

  > .rightView {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff url(https://static.bokao2o.com/wisdomDesk/images/Def_OrderCheckout_RightBorder.png) left center / 27px no-repeat;
    z-index: 10;

    > .defImgView {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      height: 180px;

      > img {
        width: 192px;
        height: 150px;
      }

      > p {
        margin-top: 10px;
        color: #666;
        line-height: 20px;
      }
    }
  }
}

#qrCodePayPopView {
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    background: transparent;
  }

  > .popView-contentView {
    border-radius: 0;
    box-shadow: none;
  }

  > .popView-contentView .qrCodePayView {
    position: relative;
    background: #fff;
    padding: 0 60px 0 30px;

    > .contentView {
      position: absolute;
      left: 30px;
      right: 60px;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      > .amtView {
        line-height: 50px;
        font-size: 32px;
        color: #ff5e56;

        > span {
          font-size: 36px;
          margin-left: 5px;
        }
      }

      > .imgView {
        margin-top: 20px;
        height: 46px;

        > img {
          width: 120px;
          height: 46px;
        }
      }

      > .qrCodeInputView {
        display: flex;
        padding: 30px 20px 20px 20px;

        > .input-scanPayCode {
          flex: 1;
          /* width: 320px; */
          /* line-height: 40px; */
          /* height: 40px; */
        }

        > button {
          width: 72px;
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          font-family: PingFangSC-Semibold;
          color: #fff;
          margin-left: 15px;
          background: #3370ff;
          border-radius: 5px;
        }
      }

      > .tipView {
        line-height: 20px;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
  }
}

.popView {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  > .popView-contentView {
    position: absolute;
    overflow: auto;
    min-height: 200px;
    background: #fff;
    border-radius: 8px;

    > .btn-close {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 40px;
      height: 40px;
      background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png) center / 28px 28px no-repeat;
    }
  }
}

.quanxiangq {
  border: 3px solid #3370ff;
  width: 320px;
  height: 130px;
  margin-top: 20px;
}

.add-dialog /deep/ .el-icon-close:before {
  color: white;
}

.add-dialog /deep/ .el-dialog__header {
  background-color: #3370ff;
}

.add-dialog /deep/ .el-dialog__title {
  color: white;
}

.receiptCount {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.receiptBth {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quanxiangq:before {
  content: "";
  position: absolute;
  left: 286px;
  bottom: 96px;
  border: 14px solid #3370ff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.quanxiangq:after {
  content: "";
  width: 3px;
  height: 10px;
  position: absolute;
  left: 304px;
  bottom: 110px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.label-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 210px;
}
</style>
