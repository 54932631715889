<template>
  <div class="orderIndexView content-index">
    <div class="topView">
      <div style="padding-bottom:15px">
        <el-page-header
          style="padding:10px"
          @back="$router.go(-1)"
          content="订单"
        ></el-page-header>
      </div>
      <div class="filterView">
        <div class="dateView">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </div>
        <div class="orderTypeView">
          <el-select
            v-model="payStatus"
            placeholder="付款状态"
            clearable
            @clear="handleFind"
          >
            <el-option
              v-for="(item, index) in payStatusOptions"
              :key="index"
              :label="item.label"
              :value="item.val"
            ></el-option>
          </el-select>
        </div>
        <div class="orderTypeView">
          <el-select
            v-model="orderStatus"
            clearable
            placeholder="订单状态"
            @clear="handleFind"
          >
            <el-option
              v-for="(item, index) in orderStatusOptions"
              :key="index"
              :label="item.label"
              :value="item.val"
            ></el-option>
          </el-select>
        </div>
        <div class="orderTypeView">
          <el-select
            v-model="orderSource"
            clearable
            placeholder="订单来源"
            @clear="handleFind"
          >
            <el-option
              v-for="(item, index) in orderSourceOptions"
              :key="index"
              :label="item.label"
              :value="item.val"
            ></el-option>
          </el-select>
        </div>
        <!-- <div class="keywordView">
          <el-input v-model="phone" clearable @clear="handleFind"
                    placeholder="请输入用户手机号"></el-input>
        </div> -->
        <div class="keywordView">
          <el-input
            v-model.trim="searchOrderNumber"
            placeholder="请输入订单号/衣物号/手机号"
            clearable
            @clear="handleFind"
            @blur="eliminate"
          ></el-input>
        </div>
        <div class="keywordView">
          <el-button type="success" @click="handleFind(1)" :loading="searchLoading">确认</el-button>
        </div>
      </div>
    </div>

    <div class="tableTitle">
      <p>
        <span>收衣单数: {{ total }} 单</span>
        <span>收衣件数: {{ clothesNum }} 件</span>
      </p>
    </div>

    <div class="orderRepView">
      <div class="headerView flexView">
        <div>序号</div>
        <div>订单号</div>
        <div>订单来源</div>
        <div>联系人</div>
        <div>电话</div>
        <div>订单状态</div>
        <div>下单方式</div>
        <div>件数</div>
        <div>下单时间</div>
        <div>付款状态</div>
        <div>实付金额</div>
      </div>
      <div
        class="listView"
        style="height: calc(100vh - 320px);"
        v-infinite-scroll="loadOrderArr"
        infinite-scroll-distance="10"
        infinite-scroll-disabled="disableLoadOrderArr"
      >
        <div
          class="orderItem flexView"
          v-for="(item, index) in orderArr"
          :key="index"
        >
          <div>{{ index + 1 }}</div>
          <div>
            <span v-if="item.orderType == 1 || item.orderType == 2">
              <el-tag class="attach" type="danger" size="mini">团</el-tag>
            </span>
            {{ item.orderNumber }}
            <el-button
              type="text"
              class="copyBth"
              @click.stop="handleCopy(item.orderNumber)"
            >
              复制
            </el-button>
          </div>
          <div>{{ item.orderSource }}</div>
          <div>{{ item.userName }}</div>
          <div>
            {{ item.userPhone }}
            <el-button
              type="text"
              class="copyBth"
              @click.stop="handleCopy(item.userPhone)"
            >
              复制
            </el-button>
          </div>
          <div>
            <el-tag>{{ item.orderStatus }}</el-tag>
          </div>
          <div>
            {{ item.oneClickOrder === 1 ? "正常下单" : "一键下单" }}
          </div>
          <div>{{ item.clothesNum }}</div>
          <div>{{ item.orderTime }}</div>
          <div>{{ item.payStatus }}({{ item.payWay }})</div>
          <div>￥{{ item.actuallyPaidPrice }}</div>
          <el-button-group class="btn-edit">
            <el-button
              :type="item.payStatus == '未支付' ? 'danger' : 'success'"
              style="margin-right: 1px;"
              v-if="
                item.orderStatus != '已取消' && item.orderStatus != '已退款'
              "
              @click.stop="handleOrderRefundShow(item)"
            >
              {{ item.payStatus == "未支付" ? "取消" : "申请退款" }}
            </el-button>
            <el-button
              type="primary"
              @click.stop="getClothesOrderDetails(item.orderNumber)"
            >
              详情
            </el-button>
          </el-button-group>
        </div>
        <div v-if="loadingOrderArr" class="moreItem">加载中...</div>
        <div v-if="noMoreOrderArr" class="moreItem">没有更多订单哦</div>
      </div>
    </div>

    <!-- 订单详情 -->
    <transition name="userDetails">
      <!-- v-if="dialogTableVisible" -->
      <order-detail
        v-if="dialogTableVisible"
        :orderDetailOption="orderDetailOption"
        :orderNumber="orderNumber"
        :whereFrom="whereFrom"
        @diaLogIsShow="dialogClose"
        @detailedInquiry="detailedInquiry"
      ></order-detail>
    </transition>

    <pop-view
      id="refundViewPopView"
      :show="showModal"
      width="1000px"
      title="订单申请退款"
      @cancel="showModal = false"
    >
      <div
        class="contentView"
        v-loading="orderRefundLoading"
        :element-loading-text="orderRefundLoadingVal"
      >
        <div class="topView">
          <div>
            付款方式:
            <span>{{ orderClothesDetail.payWay }}</span>
          </div>
          <div>
            退款金额:
            <span style="color: red;">{{ refundAmount }}元</span>
          </div>
          <div>
            退回路径:
            <span>原路退回</span>
          </div>
        </div>
        <div class="topView" style="padding-top:0">
          <div>
            优惠券总金额:
            <span style="color: red;"
              >{{ orderClothesDetail.couponAmount }}元</span
            >
          </div>
          <div class="coupon-name">
            优惠券名称:
            <span>{{
              orderClothesDetail.couponName
                ? orderClothesDetail.couponName
                : "无"
            }}</span>
          </div>
        </div>
        <div class="topView" style="padding-top:0">
          <span style="color: red;margin:0 30px">
            温馨提示:
            单笔订单部分衣物发生退款时，使用的优惠券是不会退还的，只会退单件实际付款的钱。</span
          >
        </div>
        <div class="headerView">
          <div>选项</div>
          <div>衣物号</div>
          <div>衣物名称</div>
          <div>衣物状态</div>
          <div>衣物金额</div>
          <div>优惠券金额</div>
          <div>应退金额</div>
          <div>退款原因</div>
        </div>
        <div class="listView" style="max-height: 441px;">
          <div
            class="listItem"
            v-for="(item, index) in checkArr"
            :key="index"
            :style="{
              'text-decoration':
                item.clothesStatus === '已退洗' ? 'line-through' : '',
            }"
          >
            <div>
              <el-checkbox
                :value="
                  (checkedClothesList.indexOf(item.clothesNum) === -1
                    ? '-'
                    : '') + item.clothesNum
                "
                :true-label="item.clothesNum"
                :false-label="'-' + item.clothesNum"
                :disabled="
                  item.clothesStatus === '已退洗' ||
                    (item.clothesStatus === '待收衣' &&
                      item.orderSource === '小程序订单') ||
                    orderClothesDetail.payWay == '美团/点评'
                "
                @change="changeCheck"
              ></el-checkbox>
            </div>
            <div>{{ item.outClothesNum }}</div>
            <div>{{ item.clothesName }}</div>
            <div>{{ item.clothesStatus }}</div>
            <div>{{ item.currentPrice }}</div>
            <div>{{ item.couponPrice }}</div>
            <div>{{ item.actuallyPaidPrice }}</div>
            <div>
              <el-input
                placeholder="请填写退款原因"
                :disabled="
                  item.clothesStatus === '已退洗' ||
                    (item.clothesStatus === '待收衣' &&
                      item.orderSource === '小程序订单') ||
                    orderClothesDetail.payWay == '美团/点评'
                "
                v-model="item.refundReason"
              ></el-input>
            </div>
          </div>
        </div>
        <!-- <div
          v-if="!isApplets"
          style="display: flex; justify-content: center; align-items: center;"
        >
          <span style="margin-right: 10px;">退款原因:</span>
          <el-input
            placeholder="请填写退款原因"
            type="textarea"
            :rows="1"
            v-model="refundReason"
            style="width: 300px;"
          ></el-input>
        </div> -->
        <div class="btnView">
          <el-button type="primary" @click="showModal = false">
            取消申请退款
          </el-button>
          <el-button type="primary" @click="handleOrderRefund">
            确认申请退款
          </el-button>
        </div>
      </div>
    </pop-view>
    <!-- 退款打印小票 -->
    <!-- <el-dialog
      :visible="printDialogVisible"
      top="20vh"
      width="650px"
      :show-close="false"
      class="print-dialog"
    >
      <template slot="title">
        <div class="print-title">
          退款成功
        </div>
      </template>
      <div class="print-content">
        <div class="item">
          <span class="text">退款金额：</span
          ><span style="color:red">￥{{ refundData.refundAmount }}</span>
        </div>
        <div class="item">
          <span class="text">扣除提成金额：</span>
          <span style="color:red">￥{{ refundData.commissionAmount }}</span>
        </div>
        <div class="item print-button">
          <el-button @click="printReceipts">打印小票</el-button>
        </div>
        <div class="item">
          <el-button type="primary" @click="backCashier">返回收银台</el-button>
        </div>
      </div>
    </el-dialog> -->
    <!-- 取消订单 -->
    <CancelOrder
      :visible="cancelOrderVisible"
      @close="closeCancelOrder"
      @enter="enterCancelOrder"
    />
  </div>
</template>

<script>
import {
  getClothesOrderPage,
  getClothesOrderDetails,
  orderRefundExamine,
  getClothesOrderForRefund,
  cancelOrder,
} from "@/api/clothes/order";
import PopView from "@/components/PopView";
// import { dateFormat } from "@/utils/date";
import { getLodop } from "@/print/LodopFuncs";
import OrderDetail from "./components/OrderDetails";
import CancelOrder from "@/components/cancelOrder";

export default {
  name: "Order",
  components: {
    OrderDetail,
    PopView,
    CancelOrder,
  },
  props: {},
  data() {
    return {
      cancelOrderVisible: false,
      remarksText: "", //催单弹出层的备注信息
      dialogVisible: false,
      orderRemNumber: 0, //催单订单的数量
      dialogTableVisible: false,
      payStatus: "",
      payStatusOptions: [
        {
          label: "未支付",
          val: "1",
        },
        {
          label: "已支付",
          val: "2",
        },
      ],
      orderStatus: "",
      orderStatusOptions: [
        {
          label: "待收衣",
          val: "1",
        },
        {
          label: "已收衣",
          val: "2",
        },
        {
          label: "清洗中",
          val: "3",
        },
        {
          label: "待取衣",
          val: "4",
        },
        {
          label: "待评价",
          val: "5",
        },
        {
          label: "已完成",
          val: "6",
        },
        {
          label: "已取消",
          val: "7",
        },
        {
          label: "已退款",
          val: "8",
        },
      ],
      orderSourceOptions: [
        {
          label: "门店订单",
          val: "1",
        },
        {
          label: "小程序订单",
          val: "2",
        },
        {
          label: "美团订单",
          val: "3",
        },
        {
          label: "口碑订单",
          val: "4",
        },
        {
          label: "太保订单",
          val: "5",
        },
        {
          label: "抖音订单",
          val: "6",
        },
      ],
      orderArr: [],
      loadingOrderArr: false,
      noMoreOrderArr: false,
      currentPage: 1,
      pageSize: 20,
      total: 0, // 总数量
      clothesNum: 0,
      moreList: [],
      orderSource: "",
      orderNumber: "",
      searchOrderNumber: "",
      phone: "",
      timeStart: new Date(),
      timeEnd: new Date(),
      dateRange: [
        this.$moment(new Date()).format("YYYY-MM-DD"),
        this.$moment(new Date()).format("YYYY-MM-DD"),
      ],
      orderDetailOption: {
        clothesList: [],
      },
      orderInfoLoading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, start]);
            },
          },
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== "") {
            const one = 90 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        },
      },
      clothes: {},
      clothesStatusList: [],
      tabs: [
        { val: "info", title: "信息" },
        { val: "status", title: "状态" },
      ],
      currentTab: "info",
      //退款
      checkArr: [],
      showModal: false,
      orderClothesDetail: {},
      checkedClothesList: [],
      orderRefundLoading: false,
      orderRefundLoadingVal: "加载中...",
      refundAmount: 0,
      // 支付
      payView: false,
      // 支付方式
      pay_ways: [
        {
          name: "余额",
          ico: "余",
          label: 3,
        },
        {
          name: "现金",
          ico: "现",
          label: 4,
        },
        {
          name: "收钱吧",
          ico: "收",
          label: 6,
        },
      ],
      payWay: "",
      payWayView: false,
      // 支付类型
      payType: 1,
      // 用户余额
      userAccount: {},
      // 衣物订单价格
      clothesOrderPrice: {},
      payVal: "",
      myInterval: null,
      shouInterval: null,
      // 付款码付款
      qrCodePayView: false,
      // 付款码提示
      qrCodePayViewName: "收款中...",
      payBtnLoading: false,
      payBtnLoadingVal: "收衣中...",
      outTradeNo: "",
      orderCheckoutVal: "请选择结账方式",
      payPrice: "",
      // 自定义高度
      screenHeight: document.documentElement.clientHeight,
      // 打印
      priCheckedClothesList: [],
      refundUserName: "",
      clothesNumList: [], //衣物详情中--选中时的衣物号
      // isApplets: true,
      refundReason: "",
      whereFrom: false,
      num: 0,
      printDialogVisible: false,
      refundData: {},
      cancelOrderItem: {},
      searchLoading:false
    };
  },
  computed: {
    disableLoadOrderArr() {
      return this.loadingOrderArr || this.noMoreOrderArr;
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log(from.name);
    if (
      from.name === "myCommission" ||
      from.name === "inService" ||
      from.name === "storeCommission"
    ) {
      next((vm) => {
        vm.setData(true);
      });
    } else {
      next((vm) => {
        vm.setData(false);
      });
    }
  },
  created() {
    // 如果是带有订单号则进入订单详情
    this.orderNumber = this.$route.query.orderNumber;
    if (this.orderNumber) {
      this.getClothesOrderDetails(this.orderNumber);

      // this.getContinue();
    }
    // this.getRemNumber();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.screenHeight = window.screenHeight;
        console.log(that.screenHeight);
      })();
    };
  },
  methods: {
    setData(flag) {
      this.whereFrom = flag;
    },
    handleFind: function(flag) {
      this.orderArr = [];
      this.currentPage = 1;
      this.loadOrderArr(flag);
    },
    eliminate() {
      if (this.searchOrderNumber == "") {
        return false;
      } else {
        this.dateRange = null;
      }
    },
    loadOrderArr(flag) {
      // console.log(this.timeStart);
      console.log("触发无限滚动", flag);
      var _this = this;
      this.loadingOrderArr = true;
      // 在这里请求api，获取数据后 push给orderArr
      console.log(_this.currentPage);
      const params = new URLSearchParams();
      params.append("current", this.currentPage);
      params.append("size", this.pageSize);
      params.append(
        "beginTime",
        this.dateRange === null ? "" : this.dateRange[0]
      );
      params.append(
        "endTime",
        this.dateRange === null ? "" : this.dateRange[1]
      );
      params.append("payStatus", this.payStatus);
      params.append("phone", this.phone);
      params.append("orderSource", this.orderSource);
      params.append("orderStatus", this.orderStatus);
      params.append("orderNumber", this.searchOrderNumber);
      this.searchLoading = true;
      getClothesOrderPage(params).then((response) => {
        _this.moreList = response.data.data.orderList;
        _this.total = response.data.data.total;
        _this.clothesNum = response.data.data.clothesNum;
        if (_this.moreList.length === 0) {
          _this.noMoreOrderArr = true;
        } else {
          _this.noMoreOrderArr = false;
          _this.currentPage++;
          _this.moreList.forEach(function(item) {
            _this.orderArr.push(item);
          });
        }
        if (_this.orderArr.length >= _this.total) {
          _this.noMoreOrderArr = true;
        }
        _this.loadingOrderArr = false;
        this.searchLoading = false;
      }).catch(()=>{
         this.searchLoading=false
      });
    },
    //获取订单详情
    getClothesOrderDetails(orderNumber) {
      this.dialogTableVisible = true;
      this.orderInfoLoading = true;
      this.orderNumber = orderNumber;
      getClothesOrderDetails(orderNumber).then((response) => {
        console.log(response);
        this.orderInfoLoading = false;
        this.orderDetailOption = response.data.data;
        console.log(this.orderDetailOption, "-------------------");
      });
    },
    detailedInquiry(val) {
      this.getClothesOrderDetails(val);
    },
    dialogClose(isShow) {
      if (this.whereFrom) {
        this.dialogTableVisible = isShow;
        this.$router.back();
      } else {
        this.dialogTableVisible = isShow;
      }
    },
    // 关闭取消订单弹框
    closeCancelOrder() {
      this.cancelOrderVisible = false;
    },
    enterCancelOrder(value) {
      let data = {
        orderNumber: this.cancelOrderItem.orderNumber,
        cancelOrderReason: value,
      };
      cancelOrder(data)
        .then((response) => {
          if (response.data.code === 0) {
            this.$message.success("取消订单成功");
            this.orderArr = this.orderArr.map((item) => {
              if (item.orderNumber == this.cancelOrderItem.orderNumber) {
                item.orderStatus = "已取消";
                item.orderFlag = "已取消";
              }
              return item;
            });
            this.cancelOrderVisible = false;
          } else {
            this.$message.error(response.data.msg);
            this.cancelOrderVisible = false;
          }
        })
        .catch(() => {
          this.cancelOrderVisible = false;
        });
    },
    // 退款展示
    handleOrderRefundShow(item) {
      if (item.payStatus == "未支付") {
        this.cancelOrderItem = { ...item };
        this.cancelOrderVisible = true;
      } else {
        this.orderRefundLoading = true;
        this.refundAmount = 0;
        getClothesOrderForRefund(item.orderNumber).then((response) => {
          this.orderClothesDetail = response.data.data;
          this.orderNumber = response.data.data.orderNumber;
          if (item.payWay === "美团/点评") {
            this.checkArr =
              this.orderClothesDetail.clothesList &&
              this.orderClothesDetail.clothesList.map((t) => ({
                refundReason: "美团订单退款",

                ...t,
              }));
            this.checkArr.forEach((item) => {
              this.checkedClothesList.push(item.clothesNum);
            });
            this.refundAmount = this.orderClothesDetail.clothesList.reduce(
              (sum, item) => this.amend(sum, item.actuallyPaidPrice, "+"),
              0
            );
            this.orderRefundLoading = false;
          } else if (
            item.orderSource === "小程序订单" &&
            item.orderStatus === "待收衣"
          ) {
            this.checkArr =
              this.orderClothesDetail.clothesList &&
              this.orderClothesDetail.clothesList.map((t) => ({
                refundReason: "小程序退款",
                orderSource: "小程序订单",
                ...t,
              }));
            this.checkArr.forEach((item) => {
              this.checkedClothesList.push(item.clothesNum);
            });
            this.refundAmount = this.orderClothesDetail.clothesList.reduce(
              (sum, item) => this.amend(sum, item.actuallyPaidPrice, "+"),
              0
            );
            this.orderRefundLoading = false;
            console.log(this.checkArr);
          } else {
            this.checkArr =
              this.orderClothesDetail.clothesList &&
              this.orderClothesDetail.clothesList.map((t) => ({
                refundReason: "",
                ...t,
              }));
            this.orderRefundLoading = false;
            this.checkedClothesList = [];
            this.refundReason = "";
          }
          // if (
          //   item.orderSource === '美团订单' ||
          //   response.data.data.couponId != 0
          // ) {
          //   // this.isApplets = false
          //   if (item.orderStatus !== '已退款') {
          //     console.log(this.checkArr)
          //     this.checkArr.forEach((item1) => {
          //       if (
          //         item1.clothesStatus != '已退洗' &&
          //         item1.clothesStatus != '已退衣'
          //       ) {
          //         this.refundAmount = item.actuallyPaidPrice
          //         this.checkedClothesList.push(item1.clothesNum)
          //       }
          //     })
          //   }
          // } else {
          //   // this.isApplets = true
          //   // this.refundAmount = item.
          // }
        });
        this.showModal = true;
      }
    },
    // 退款展示
    // handleOrderRefundShow(item) {
    //   this.orderRefundLoading = true
    //   getClothesOrderForRefund(item.orderNumber).then((response) => {
    //     console.log(response.data.data,'999999');
    //     this.orderClothesDetail = response.data.data
    //     this.orderNumber = response.data.data.orderNumber
    //       this.checkArr =
    //         this.orderClothesDetail.clothesList &&
    //         this.orderClothesDetail.clothesList.map((t) => ({
    //           refundReason: '美团订单退款',
    //           ...t,
    //         }))
    //       this.checkArr.forEach((item) => {
    //         this.checkedClothesList.push(item.clothesNum)
    //       })
    //       let refundClothesList = this.checkArr.filter(
    //         (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1,
    //       )
    //       this.refundAmount = refundClothesList.reduce(
    //         (sum, item) => (sum += item.currentPrice),
    //         this.orderClothesDetail.fare,
    //       )
    //       this.orderRefundLoading = false
    //   })

    //   this.showModal = true
    // },
    // 选中状态
    changeCheck(val) {
      console.log(val);
      if (val.indexOf("-") === -1) {
        this.checkedClothesList.push(val);
        let refundClothesList = this.checkArr.filter(
          (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1
        );
        this.refundAmount = refundClothesList.reduce(
          (sum, item) => this.amend(sum, item.actuallyPaidPrice, "+"),
          0
        );
      } else {
        this.checkedClothesList = this.checkedClothesList.filter(
          (i) => i !== val.slice(1)
        );
        let refundClothesList = this.checkArr.filter(
          (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1
        );
        this.refundAmount = refundClothesList.reduce(
          (sum, item) => this.amend(sum, item.actuallyPaidPrice, "+"),
          0
        );
      }
    },
    amend(num1, num2, symbol) {
      var str1 = num1.toString(),
        str2 = num2.toString(),
        result,
        str1Length,
        str2Length;
      //解决整数没有小数点方法
      try {
        str1Length = str1.split(".")[1].length;
      } catch (error) {
        str1Length = 0;
      }
      try {
        str2Length = str2.split(".")[1].length;
      } catch (error) {
        str2Length = 0;
      }
      var step = Math.pow(10, Math.max(str1Length, str2Length));
      //
      console.log(step);
      switch (symbol) {
        case "+":
          result = (num1 * step + num2 * step) / step;
          break;
        case "-":
          result = (num1 * step - num2 * step) / step;
          break;
        case "*":
          result = (num1 * step * (num2 * step)) / step / step;
          break;
        case "/":
          result = (num1 * step) / (num2 * step);
          break;
        default:
          break;
      }
      return result;
    },
    // 订单退款
    handleOrderRefund() {
      if (this.checkedClothesList.length === 0) {
        this.$message.error("请选择退款衣物");
        return false;
      }
      // 判断是否填写退款原因
      let refundClothesList = this.checkArr.filter(
        (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1
      );
      let newData = refundClothesList.filter((item) => !item.refundReason);
      if (newData.length > 0) {
        this.$message.error("请填写退款原因");
        return;
      }
      // if (!this.isApplets) {
      //   if (!this.refundReason) return this.$message.error('请填写退款原因')
      //   this.checkArr.forEach((item) => {
      //     item.refundReason = this.refundReason
      //   })
      // } else {
      // }
      this.$confirm("确定要申请退款吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.orderRefundLoading = true;
          this.orderRefundLoadingVal = "申请退款中...";
          let orderNumber = this.orderNumber;
          // let arr = this.checkArr.filter(
          //   (item) => item.clothesStatus == "已退洗"
          // );
          let refundClothesList = this.checkArr.filter(
            (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1
          );
          let data = {
            orderNumber,
            refundClothesList,
          };
          // let arrLength = [...arr, ...refundClothesList].length;
          orderRefundExamine(data)
            .then((response) => {
              if (response.data.code === 0) {
                // this.orderArr = this.orderArr.map((item) => {
                //   if (
                //     item.orderNumber == this.orderNumber &&
                //     arrLength == this.checkArr.length
                //   ) {
                //     item.orderStatus = "已退款";
                //     item.orderFlag = "已退款";
                //   }
                //   return item;
                // });
               
                this.$message.success('申请退款成功！');
                this.refundData = response.data.data;
                this.refundUserName = response.data.data.operateUserName;
                 this.showModal=false
                // this.getClothesOrderDetails(this.orderNumber);
                //打印
                
                this.printDialogVisible = true;
                // this.printRefundPaper();
              } else {
                this.$message.error(response.data.msg);
              }
              this.orderRefundLoading = false;
            })
            .catch((error) => {
              this.orderRefundLoading = false;
            });
        })
        .catch(() => {
          this.orderRefundLoading = false;
        });
    },
    // 处理复制
    handleCopy(value) {
      let _this = this;
      _this.$copyText(value).then(
        function(e) {
          _this.$message.success("复制成功");
        },
        function(e) {
          _this.$message.error("复制失败");
        }
      );
    },

    // 打印退款
    printRefundPaper() {
      let LODOP = getLodop();
      LODOP.SET_LICENSES(
        "",
        "660FDFD7D9F7F7101A272F2EB57C306A",
        "C94CEE276DB2187AE6B65D56B3FC2848",
        ""
      );

      LODOP.PRINT_INIT("收银小票"); //首先一个初始化语句
      LODOP.SET_PRINT_PAGESIZE(3, "75mm", "20mm", "收银小票");
      //门店信息
      console.log(this.orderClothesDetail);
      LODOP.ADD_PRINT_TEXT(
        "0mm",
        "0mm",
        "75mm",
        "4mm",
        this.orderClothesDetail.store.storeName
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
      //门店电话
      LODOP.ADD_PRINT_TEXT(
        "6mm",
        "0mm",
        "75mm",
        "3mm",
        "门店电话：" + this.orderClothesDetail.store.phone
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      //门店地址
      if (this.orderClothesDetail.store.address.length > 16) {
        LODOP.ADD_PRINT_TEXT(
          "10mm",
          "0mm",
          "75mm",
          "3mm",
          "地址：" + this.orderClothesDetail.store.address.substring(0, 16)
        );
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        LODOP.ADD_PRINT_TEXT(
          "14mm",
          "0mm",
          "75mm",
          "3mm",
          this.orderClothesDetail.store.address.substring(16)
        );
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      } else {
        LODOP.ADD_PRINT_TEXT(
          "10mm",
          "0mm",
          "75mm",
          "3mm",
          "地址：" + this.orderClothesDetail.store.address
        );
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      }

      //线
      LODOP.ADD_PRINT_LINE("16mm", 0, "16mm", "75mm", 3, 0);
      //衣物信息标题
      LODOP.ADD_PRINT_TEXT("18mm", "0mm", "75mm", "4mm", "衣物退款信息");
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
      //订单号条形码
      LODOP.ADD_PRINT_BARCODE(
        "24mm",
        "10mm",
        "55mm",
        "9mm",
        "128Auto",
        this.orderNumber
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
      //订单号
      LODOP.ADD_PRINT_TEXT("33mm", "0mm", "75mm", "4mm", this.orderNumber);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

      let height = 33;

      let refundClothesList = this.checkArr.filter(
        (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1
      );

      //衣物列表：名称 服务 件数 原价 实收
      if (refundClothesList.length > 0) {
        LODOP.ADD_PRINT_TEXT("38mm", "0mm", "75mm", "4mm", "名称");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        LODOP.ADD_PRINT_TEXT("38mm", "15mm", "75mm", "4mm", "项目");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        LODOP.ADD_PRINT_TEXT("38mm", "30mm", "75mm", "4mm", "件数");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        LODOP.ADD_PRINT_TEXT("38mm", "45mm", "75mm", "4mm", "原价");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        LODOP.ADD_PRINT_TEXT("38mm", "60mm", "75mm", "4mm", "实收");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
        height = height + 5;
        height = height + refundClothesList.length * 4;
      }

      //衣物列表
      for (let i = 0; i < refundClothesList.length; i++) {
        let clothes = refundClothesList[i];
        LODOP.ADD_PRINT_TEXT(
          42 + i * 4 + 1 + "mm",
          "0mm",
          "75mm",
          "4mm",
          clothes.clothesName
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.ADD_PRINT_TEXT(
          42 + i * 4 + 1 + "mm",
          "15mm",
          "75mm",
          "4mm",
          clothes.clothesServiceName
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.ADD_PRINT_TEXT(
          42 + i * 4 + 1 + "mm",
          "30mm",
          "75mm",
          "4mm",
          clothes.num
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.ADD_PRINT_TEXT(
          42 + i * 4 + 1 + "mm",
          "45mm",
          "75mm",
          "4mm",
          clothes.originalPrice
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
        LODOP.ADD_PRINT_TEXT(
          42 + i * 4 + 1 + "mm",
          "60mm",
          "75mm",
          "4mm",
          clothes.currentPrice
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      }

      //注意：*衣物详细信息请进入洗衣通小程序查看*
      height = height + 6;
      LODOP.ADD_PRINT_TEXT(
        height + "mm",
        "0mm",
        "75mm",
        "4mm",
        "*衣物详细信息请进入洗衣通小程序查看*"
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);

      height = height + 6;
      // 线
      LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

      //收银信息
      height = height + 4;
      LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "退款信息");
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      //收银详情
      height = height + 6;
      LODOP.ADD_PRINT_TEXT(
        height + "mm",
        "0mm",
        "75mm",
        "4mm",
        "客户姓名：" + this.orderClothesDetail.orderRealName
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
      LODOP.ADD_PRINT_TEXT(
        height + "mm",
        "37.5mm",
        "75mm",
        "4mm",
        "联系电话：" +
          this.orderClothesDetail.orderUserPhone.replace(
            this.orderClothesDetail.orderUserPhone.substring(3, 7),
            "****"
          )
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

      if (
        this.orderClothesDetail.cardNo !== "" &&
        this.orderClothesDetail.cardNo !== null
      ) {
        height = height + 5;
        LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "0mm",
          "75mm",
          "4mm",
          "会员卡号：" +
            this.orderClothesDetail.cardNo.replace(
              this.orderClothesDetail.cardNo.substring(
                this.orderClothesDetail.cardNo.length / 2,
                this.orderClothesDetail.cardNo.length
              ),
              "****"
            )
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
        LODOP.ADD_PRINT_TEXT(
          height + "mm",
          "37.5mm",
          "75mm",
          "4mm",
          "卡内余额：" + this.orderClothesDetail.balance
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
      }

      height = height + 5;
      LODOP.ADD_PRINT_TEXT(
        height + "mm",
        "0mm",
        "75mm",
        "4mm",
        "退款总额：" + this.refundAmount
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
      LODOP.ADD_PRINT_TEXT(
        height + "mm",
        "37.5mm",
        "75mm",
        "4mm",
        "操作店长：" + this.refundUserName
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

      height = height + 5;
      LODOP.ADD_PRINT_TEXT(
        height + "mm",
        "0mm",
        "75mm",
        "4mm",
        "退款时间：" + this.getTime()
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
      //线
      height = height + 6;
      // 线
      LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

      //二维码
      height = height + 5;
      LODOP.ADD_PRINT_BARCODE(
        height + "mm",
        "25mm",
        "30mm",
        "30mm",
        "QRCode",
        "http://weixin.qq.com/r/6Sk8JNrEeYcGrZ5H93z7"
      );
      height = height + 25;

      //注：扫码进入小程序查看衣物详细信息
      LODOP.ADD_PRINT_TEXT(
        height + "mm",
        "0mm",
        "75mm",
        "4mm",
        "扫码进入小程序查看衣物详细信息"
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);

      //投诉服务电话：400-816-5008
      height = height + 5;
      LODOP.ADD_PRINT_TEXT(
        height + "mm",
        "0mm",
        "75mm",
        "4mm",
        "投诉服务电话：400-816-5008"
      );
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);

      LODOP.SET_PRINTER_INDEX(-1);
      LODOP.PRINT();
    },
    getTime() {
      var date1 = new Date();
      var year = date1.getFullYear();
      var month = date1.getMonth() + 1;
      var day = date1.getDate();
      var hours = date1.getHours();
      var minutes = date1.getMinutes();
      var seconds = date1.getSeconds();
      return (
        year +
        "年" +
        month +
        "月" +
        day +
        "日" +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    // 打印小票
    async printReceipts() {
      await this.printRefundPaper();
      this.getClothesOrderDetails(this.orderNumber);
      this.showModal = false;
      this.printDialogVisible = false;
    },
    // 返回收银页面
    backCashier() {
      this.$router.push("/home/get");
    },
  },
};
</script>

<style scoped lang="scss">
.print-dialog {
  ::v-deep .el-dialog__body {
    padding-bottom: 20px;
  }
  .print-title {
    text-align: center;
    color: rgba(16, 16, 16, 100);
    font-size: 20px;
  }
  .print-content {
    color: rgba(16, 16, 16, 100);
    font-size: 16px;
    padding: 20px 0;
    .item {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      .text {
        width: 114px;
        text-align: right;
      }
      .el-button {
        width: 250px;
        border: 1px solid #dcdfe6;
      }
    }
    .print-button {
      padding-top: 30px;
    }
  }
}
.el-button {
  // background: #3370FF;
}
.shopowner {
  flex: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-dialog__body {
  padding-bottom: 70px;
  padding-top: 0;

  p span {
    font-size: 16px;
    font-weight: 700;
  }

  .el-button {
    margin-top: 10px;
    float: right;
    margin-right: 5px;
  }
}
.el-tag.el-tag--danger {
  background-color: #ffcc66;
  color: #fff;
}
.orderRem {
  position: relative;

  i {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 25px;
    height: 25px;
    background-color: #ff3300;
    border-radius: 50%;
    font-style: normal;
    text-align: center;
    line-height: 25px;
  }
}
.orderIndexView {
  overflow: auto;
  > .topView {
    // height: 110px;
    padding: 20px 20px 0 20px;
    /*筛选条件*/
    > .filterView {
      display: flex;
      height: 44px;
      // padding: 10px 0 0 0;

      > div {
        height: 44px;
        margin-right: 15px;
      }

      > .keywordView {
        > button {
          background: #3370ff;
          font-size: 16px;
          font-family: PingFangSC-Medium;
          color: #fff;
        }
      }
    }
  }

  .tableTitle {
    padding: 0 20px 0;

    p {
      font-size: 16px;
      font-weight: 700;

      span {
        margin: 0 10px;
      }
    }
  }

  /*内容*/
  > .orderRepView {
    // padding: 0 10px;

    > .headerView {
      // margin-bottom: 10px;
      font-size: 14px;
      //font-weight: 700;
      color: #000;
      text-align: center;
      //background-color: #f5f7fa;
    }

    > .listView {
      overflow-x: auto;
      overflow-y: auto;
      padding-bottom: 25px;

      > .orderItem {
        position: relative;
        line-height: 20px;
        font-size: 13px;
        color: #333;
        background: #f8f8f8;
        text-align: center;

        > label {
          cursor: pointer;
          line-height: 32px;
        }

        > .btn-edit {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 20px;
          margin: auto;
          height: 40px;
          width: 140px;
          display: flex;
          justify-content: center;
        }
      }

      > .moreItem {
        margin-top: 5px;
        line-height: 40px;
        font-size: 13px;
        color: #3370ff;
        text-align: center;
      }

      .orderItem:hover {
        background: rgba(66, 153, 255, 0.1);
      }

      > .orderItem:nth-child(odd) {
        background: #fff;
      }
    }
  }

  > .orderRepView .flexView {
    display: flex;
    padding: 15px 134px 15px 20px;
    align-items: center;

    > div {
      flex: 1;
    }
    > div:nth-child(1) {
      flex: 0.5;
    }
    > div:nth-child(2) {
      flex: 1.3;
    }
    > div:nth-child(4) {
      flex: 0.8;
    }
    > div:nth-child(5) {
      flex: 1.3;
    }
    > div:nth-child(8) {
      flex: 0.5;
    }
  }
}

.clothesInfo-contentView {
  > div {
    > .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 80px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }

  > .contentView {
    > div {
      padding-left: 20px;
      padding-right: 20px;
      line-height: 20px;
    }

    > .tView {
      position: relative;
      background: #f8f8f8;
      padding: 15px 20px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium;
      color: #333;
      overflow: hidden;

      .el-button {
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }

    .cashContentView {
      max-height: 300px;
      overflow: auto;
    }

    > .cashContentView .flexView {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 14px;
      color: #333;
      // line-height: 20px;
      padding: 6px 0;

      label:nth-child(1) {
        flex: 0.2;
        text-align: center;
      }

      label {
        flex: 1;
        cursor: pointer;
        text-align: center;
      }
    }
  }

  > .payInfoView {
    padding-bottom: 25px;

    > div {
      display: flex;
      color: #333;
      line-height: 20px;
      font-size: 14px;
      padding: 6px 20px 6px 20px;
    }

    > div .label-leftText {
      width: 80px;
      min-width: 80px;
      color: #999;
    }

    > div .label-amt {
      display: inline-block;
      width: 200px;
      margin-top: 10px;
      color: #ff5e56;
      font-family: DINAlternate-Bold;
    }

    > div {
      > div {
        margin-top: 10px;
      }

      > div.payTypeView {
        margin-left: 20px;

        .payTypeItem {
          margin-top: 10px;
        }

        .label-name {
          display: inline-block;
          width: 200px;
          color: #ff5e56;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 0.9;
          font-family: DINAlternate-Bold;
        }
      }

      > div.remarkView {
        margin-left: 20px;

        .remarkCtn {
          margin-top: 10px;
        }

        .remark-box {
          display: inline-block;
          width: 200px;
        }
      }
    }

    > .tView {
      position: relative;
      background: #f8f8f8;
      padding: 15px 20px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium;
      color: #333;
      overflow: hidden;

      > button {
        position: absolute;
        top: 0;
        right: 20px;
        line-height: 50px;
        height: 50px;
        background: transparent;
        font-size: 14px;
        padding: 0;
        color: #3370ff;
      }
    }
  }
}

button {
  /*background: transparent;*/
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

.memberDetailView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  padding: 120px 20px 25px 25px;
  z-index: 100;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 90px;*/
    padding: 20px 25px 0 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .menuView {
        flex: 1;
        height: 60px;
        line-height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 0 100px 0 160px; */
        /*> /deep/ .el-tabs__nav-wrap::after {*/
        /*    position: static !important;*/
        /*}*/

        .menuItem {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          padding: 0 25px;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333;

          > .select {
            position: relative;
            color: #3370ff;
          }

          > .select:after {
            content: "";
            left: 0;
            right: 0;
            bottom: -8px;
            position: absolute;
            width: 24px;
            height: 2px;
            background: #3370ff;
            margin: auto;
            border-radius: 1px;
          }
        }
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.4s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.4s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.4s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.4s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

#order-detailViewPopView > .popView-contentView {
  > .menuView {
    position: absolute;
    width: 88px;
    display: flex;
    top: 45px;
    left: 0;
    right: 0;
    margin: auto;

    > button {
      font-size: 16px;
      color: #333;
      flex: 1;
      line-height: 30px;
      height: 30px;
      padding: 0;
    }

    > button.select {
      position: relative;
      color: #3370ff;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
    }

    > button.select:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0px;
      position: absolute;
      width: 24px;
      height: 2px;
      background: #3370ff;
      margin: auto;
      border-radius: 1px;
    }
  }

  > .contentView {
    .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 99px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }
}

#refundViewPopView > .popView-contentView > .contentView {
  padding: 0 15px;

  > .topView {
    background: #fff;
    padding: 10px 0;
    display: flex;

    > div {
      // width: 145px;
      margin-right: 20px;
    }

    > div:nth-child(1) {
      margin-left: 30px;
    }
    .coupon-name {
      max-width: 320px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  > .headerView {
    display: flex;
    text-align: center;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    color: #999;

    > div {
      flex: 1;
    }

    > div:nth-child(1) {
      flex: 0.5;
    }
  }

  > .listView {
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    padding-bottom: 25px;

    > .listItem {
      position: relative;
      display: flex;
      padding: 10px 0;
      line-height: 30px;
      font-size: 14px;
      color: #333;
      background: #fff;

      > div {
        flex: 1;

        > .btn-select {
          width: 60px;
          height: 100%;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
            center / 28px no-repeat;
        }

        .btn-select.select {
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png)
            center / 28px no-repeat;
        }
      }

      > div:nth-child(1) {
        flex: 0.5;
      }
    }
  }

  > .btnView {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.copyBth {
  margin-left: 5px;
  font-size: 10px;
  padding: 0;
  background-color: #fff;
  color: #3370ff;
}

.popView {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  > .popView-contentView {
    position: absolute;
    overflow: auto;
    min-height: 200px;
    background: #fff;
    border-radius: 8px;

    > .btn-close {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 40px;
      height: 40px;
      background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png)
        center / 28px 28px no-repeat;
    }
  }
}

#qrCodePayPopView {
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    background: transparent;
  }

  > .popView-contentView {
    border-radius: 0;
    box-shadow: none;
  }

  > .popView-contentView .qrCodePayView {
    position: relative;
    background: #fff;
    padding: 0 60px 0 30px;

    > .contentView {
      position: absolute;
      left: 30px;
      right: 60px;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      > .amtView {
        line-height: 50px;
        font-size: 32px;
        color: #ff5e56;

        > span {
          font-size: 36px;
          margin-left: 5px;
        }
      }

      > .imgView {
        margin-top: 20px;
        height: 46px;

        > img {
          width: 120px;
          height: 46px;
        }
      }

      > .qrCodeInputView {
        display: flex;
        padding: 30px 20px 20px 20px;

        > .input-scanPayCode {
          flex: 1;
          /* width: 320px; */
          /* line-height: 40px; */
          /* height: 40px; */
        }

        > button {
          width: 72px;
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          font-family: PingFangSC-Semibold;
          color: #fff;
          margin-left: 15px;
          background: #3370ff;
          border-radius: 5px;
        }
      }

      > .tipView {
        line-height: 20px;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  /*background: transparent;*/
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
